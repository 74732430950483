import { Grid, LinearProgress } from "@material-ui/core";
import { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useGetTakeBackSystemsQuery } from "../../../../put-on-market/repositories/queries/take-back-system/get-take-back-systems.query";
import { useAuthContext } from "../../../../providers/Auth/auth.provider";
import {
  getTranslationKeyOfPeriod,
  PomAnnouncementPeriod,
} from "../../../../put-on-market/repositories/models/announcements/pom-announcement.period";
import { GroupType } from "../../../domain/group/group-type";
import { FormAutocomplete } from "../../form/fields/form-autocomplete.component";
import { FormCheckbox } from "../../form/fields/form-checkbox.field";
import { FormField } from "../../form/form-field.component";
import { FormHeader } from "../../form/form-header.component";
import { isAgencyOrMainContact } from "../group-form/group-form.util";
import { GroupContractFormMode, IGroupContractFormInputs } from "./group-contract-form.component";

interface IPomGroupContractFormProps {
  mode: GroupContractFormMode;
}

export const PomGroupContractForm: FC<IPomGroupContractFormProps> = (props) => {
  const { mode } = props;
  const { t } = useTranslation();
  const readOnly = mode === GroupContractFormMode.Detail;
  const { internalUser } = useAuthContext();

  const {
    formState: { errors },
    control,
    setValue,
    watch,
  } = useFormContext<IGroupContractFormInputs>();

  // @ts-ignore fix circular dependency
  const groupType: GroupType = watch("type");
  const takeBackSystemId = watch("takeBackSystemId");

  const { isLoading, data: takeBackSystems } = useGetTakeBackSystemsQuery();

  useEffect(() => {
    if (!takeBackSystems || takeBackSystems.length === 0 || !takeBackSystemId) return;

    setValue("takeBackSystemId", takeBackSystemId);
  }, [takeBackSystems, takeBackSystemId, setValue]);

  const isGroupTypeSelected = (type: GroupType): boolean => {
    return groupType === type;
  };

  return (
    <>
      {isLoading && <LinearProgress />}
      {!isLoading && (
        <>
          <Grid item className={"px-2"} xs={12} md={12}>
            <FormHeader>{t("basedata.group.pom_heading")}</FormHeader>
          </Grid>
          <FormField md={12} container>
            <FormCheckbox
              name={"defaultPriceGroup"}
              control={control}
              label={t("basedata.groups.edit.defaultPriceGroup")}
              disabled={readOnly}
              noMargin
            />
          </FormField>
          <FormField md={12}>
            <FormAutocomplete<number>
              name="takeBackSystemId"
              required
              rules={{ required: true }}
              control={control}
              disableClearable
              disabled={readOnly}
              label={t("basedata.groups.groupContracts.takeBackSystem")}
              error={Boolean(errors?.takeBackSystemId)}
              options={takeBackSystems?.map((takeBackSystem) => takeBackSystem.id) ?? []}
              getOptionLabel={(value) => {
                const takeBackSystem = (takeBackSystems ?? []).find((takeBacksSystem) => takeBacksSystem.id === value);
                return takeBackSystem?.name ?? "";
              }}
            />
          </FormField>
          <FormField md={12}>
            <FormAutocomplete<PomAnnouncementPeriod>
              name={"announcementPeriod"}
              control={control}
              required={true}
              label={t("basedata.groups.pom_announcement_period")}
              options={[PomAnnouncementPeriod.Monthly, PomAnnouncementPeriod.Yearly]}
              error={Boolean(errors?.announcementPeriod)}
              rules={{ required: true }}
              getOptionLabel={(period) => t(getTranslationKeyOfPeriod(period))}
              disabled={isGroupTypeSelected(GroupType.PomRepresentative) || isAgencyOrMainContact(internalUser)}
            />
          </FormField>
        </>
      )}
    </>
  );
};
