import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import React, { createContext, Dispatch, SetStateAction, useContext, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useAsyncEffect from "use-async-effect";
import { useAuthContext } from "../../../../providers/Auth/auth.provider";
import { useGetRegistrationOfCurrentGroupQuery } from "../../../../put-on-market/repositories/queries/batt-g/get-registration-of-current-group.query";
import { useGetCfmCorporateUsersOfCurrentGroupQuery } from "../../../../put-on-market/repositories/queries/get-cfm-corporate-users-of-current-group.query";
import { useGetPomMainContactsOfCurrentGroupQuery } from "../../../../put-on-market/repositories/queries/get-pom-main-contacts-of-current-group.query";
import { useCreateRegistrationQuery } from "../../../../put-on-market/repositories/queries/mutations/batt-g/create-registration.query";
import { useUpdateRegistrationOfCurrentGroupQuery } from "../../../../put-on-market/repositories/queries/mutations/batt-g/update-registration-of-current-group.query";
import { IBattGContactFormInputs } from "../../../components/batt-g/batt-g-contact-form.component";
import { IBattGManufacturerBaseDataFormInputs } from "../../../components/batt-g/batt-g-manufacturer-base-data-form.component";
import { IAddressLean } from "../../../domain/address/address-lean";
import { BattGRegistrationConverter } from "../../../domain/converter/batt-g/batt-g-registration.converter";
import { isPom } from "../../../domain/user/user";
import { IUserLean } from "../../../domain/user/user-lean";
import { AddressType } from "../../../models/address/address.model";
import { BattGRegistrationStatus, IBattGRegistration } from "../../../models/batt-g/batt-g-registration";
import { BattGRegistrationStatusModel } from "../../../repositories/models/batt-g/batt-g-registration.model";
import { useGetGroupAddresses } from "../../../repositories/queries/address/get-group-addresses.query";
import { BattGRegistrationStep } from "./batt-g-registration-steps";

interface IBattGRegisterProvider {
  isLoading: boolean;
  isReadOnly: boolean;
  setIsReadOnly: Dispatch<SetStateAction<boolean>>;
  groupAddress: IAddressLean | undefined;
  availableMainContacts: IUserLean[];
  registrationStatus: BattGRegistrationStatus | undefined;
  manufacturerBaseData: IBattGManufacturerBaseDataFormInputs | undefined;
  mainContact: IBattGContactFormInputs | undefined;
  representative: IBattGContactFormInputs | undefined;
  handleManufacturerBaseDataFormSubmit: (inputs: IBattGManufacturerBaseDataFormInputs) => void;
  handleMainContactFormSubmit: (inputs: IBattGContactFormInputs) => void;
  handleRepresentativeFormSubmit: (inputs: IBattGContactFormInputs, submitWithFee?: boolean) => void;
  activeStep: BattGRegistrationStep;
  handleStepChanged: (step: BattGRegistrationStep) => void;
  getLatestPublishedAt: () => Date | undefined;
}

export const BattGRegisterContext = createContext<IBattGRegisterProvider>({} as IBattGRegisterProvider);

export const useBattGRegisterContext = () => {
  return useContext(BattGRegisterContext);
};

const useBattGRegisterProvider = (): IBattGRegisterProvider => {
  const [isLoading, setIsLoading] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [availableMainContacts, setAvailableMainContacts] = useState<IUserLean[]>([]);
  const [activeStep, setActiveStep] = useState<BattGRegistrationStep>(BattGRegistrationStep.ManufacturerBaseData);
  const manufacturerBaseData = useRef<IBattGManufacturerBaseDataFormInputs | undefined>(undefined);
  const mainContact = useRef<IBattGContactFormInputs | undefined>(undefined);
  const representative = useRef<IBattGContactFormInputs | undefined>(undefined);
  const [registration, setRegistration] = useState<IBattGRegistration | undefined>(undefined);

  const { internalUser } = useAuthContext();
  const { refetch: getPomMainContactsOfCurrentGroup } = useGetPomMainContactsOfCurrentGroupQuery(false);
  const { refetch: getCfmCorporateUsersOfCurrentGroup } = useGetCfmCorporateUsersOfCurrentGroupQuery(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { mutateAsync: createRegistration, isLoading: isCreateRegistrationLoading } = useCreateRegistrationQuery();
  const { mutateAsync: updateRegistrationOfCurrentGroup, isLoading: isUpdateRegistrationOfCurrentGroupLoading } =
    useUpdateRegistrationOfCurrentGroupQuery();
  const { data: registrationOfCurrentGroup } = useGetRegistrationOfCurrentGroupQuery();

  const isMutationLoading = useMemo(
    () => isCreateRegistrationLoading || isUpdateRegistrationOfCurrentGroupLoading,
    [isCreateRegistrationLoading, isUpdateRegistrationOfCurrentGroupLoading],
  );

  const { data: groupAddresses, isLoading: areGroupAddressesLoading } = useGetGroupAddresses(
    internalUser?.group?.id,
    undefined,
    [AddressType.GroupLocation],
    true,
  );

  useAsyncEffect(
    async (isActive) => {
      setIsLoading(true);
      const contactPersons = await fetchContactPersons();

      if (!isActive()) {
        return;
      }

      if (registrationOfCurrentGroup && isActive()) {
        prefillForm(registrationOfCurrentGroup);
        setRegistration(registrationOfCurrentGroup);
        if (registrationOfCurrentGroup.status === BattGRegistrationStatus.Published) {
          setIsReadOnly(true);
        }
      }

      if (!isActive()) {
        return;
      }

      setAvailableMainContacts(contactPersons);
      setIsLoading(false);
    },
    [registrationOfCurrentGroup],
  );

  const fetchContactPersons = async (): Promise<IUserLean[]> => {
    if (isPom(internalUser)) {
      const result = await getPomMainContactsOfCurrentGroup();
      return result.data?.items ?? [];
    } else {
      const result = await getCfmCorporateUsersOfCurrentGroup();
      return result.data?.items ?? [];
    }
  };

  const handleManufacturerBaseDataFormSubmit = (inputs: IBattGManufacturerBaseDataFormInputs): void => {
    manufacturerBaseData.current = inputs;
    setActiveStep(BattGRegistrationStep.MainContactData);
  };

  const handleMainContactFormSubmit = (inputs: IBattGContactFormInputs): void => {
    mainContact.current = inputs;
    setActiveStep(BattGRegistrationStep.RepresentativeData);
  };

  const handleRepresentativeFormSubmit = (inputs: IBattGContactFormInputs, submitWithFee?: boolean): void => {
    representative.current = inputs;

    if (submitWithFee === true) {
      handleRegistrationSubmittedWithFee();
    } else {
      handleRegistrationSaved();
    }
  };

  const handleStepChanged = (step: BattGRegistrationStep): void => {
    setActiveStep(step);
  };

  const handleRegistrationSaved = async (): Promise<void> => {
    await sendRegistration(BattGRegistrationStatusModel.Draft);
  };

  const handleRegistrationSubmittedWithFee = async (): Promise<void> => {
    await sendRegistration(BattGRegistrationStatusModel.Published);
  };

  const prefillForm = (registration: IBattGRegistration): void => {
    const formInputs = BattGRegistrationConverter.toFormInputs(registration);
    manufacturerBaseData.current = formInputs.manufacturer;
    mainContact.current = formInputs.mainContact;
    representative.current = formInputs.representative;
  };

  const sendRegistration = async (status: BattGRegistrationStatusModel): Promise<void> => {
    if (!manufacturerBaseData.current || !mainContact.current || !representative.current) {
      return;
    }

    const request = BattGRegistrationConverter.registerFormInputsToRequest(
      manufacturerBaseData.current,
      mainContact.current,
      representative.current,
      status,
    );

    let response: IBattGRegistration;

    if (!registration) {
      response = await createRegistration({ request });
    } else {
      response = await updateRegistrationOfCurrentGroup({ request });
    }

    setRegistration(response);
    if (status === BattGRegistrationStatusModel.Draft) {
      enqueueSnackbar(t("batt_g.register.save_success"), { variant: "success" });
    } else {
      setIsReadOnly(true);
      enqueueSnackbar(t("batt_g.register.submit_success"), { variant: "success" });
    }
  };

  const getLatestPublishedAt = (): Date | undefined => {
    if (!registration) return undefined;

    return dayjs
      .max(
        [
          registration.manufacturer.updatedAt,
          registration.mainContactPerson.updatedAt,
          registration.representativeContactPerson.updatedAt,
        ].map(dayjs),
      )
      ?.toDate();
  };

  return {
    groupAddress: groupAddresses?.addresses?.[0],
    isLoading: isLoading || areGroupAddressesLoading || isMutationLoading,
    handleManufacturerBaseDataFormSubmit,
    activeStep,
    handleStepChanged,
    handleMainContactFormSubmit,
    handleRepresentativeFormSubmit,
    manufacturerBaseData: manufacturerBaseData.current,
    mainContact: mainContact.current,
    representative: representative.current,
    availableMainContacts,
    registrationStatus: registration?.status,
    getLatestPublishedAt,
    isReadOnly,
    setIsReadOnly,
  };
};

export const BattGRegisterProvider: React.FC = (props) => {
  const value = useBattGRegisterProvider();
  return <BattGRegisterContext.Provider value={value}>{props.children}</BattGRegisterContext.Provider>;
};
