import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  makeStyles,
  Theme,
  Typography,
  withStyles,
} from "@material-ui/core";
import { ArrowForwardIosOutlined } from "@material-ui/icons";
import classNames from "classnames";
import { ChangeEvent, FC, ReactNode, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { replaceUriParameter } from "../../../../api/endpoints";
import { OrderCheckbox } from "../../../../components/checkbox/order-checkbox.component";
import { EditIconButton } from "../../../../components/Primitives";
import { CfmProductArticleType } from "../../../../configurator/components/product/product.utils";
import { Colors, Shades } from "../../../../style/Colors";
import { ARTICLE_SEPARATOR, AvailableCfmRoutes } from "../../../../utils/constants";
import { ICfmOrderX } from "../../../domain/order-x/cfm-order-x";
import { OrderXCardPackageInformation } from "../../order-x/components/package-information/order-x-card-package-information.component";
import { getPackagesFromItems } from "../../order-x/utils/order-x-card.util";
import { CartOrderInfoRow } from "./cart-order-info-row.component";

const useStyles = makeStyles((theme: Theme) => ({
  accordion: {
    boxShadow: "none",
    borderRadius: 0,
    "&:last-child": {
      borderRadius: 0,
    },
    "&:first-child": {
      borderRadius: 0,
    },
  },
  summaryContainer: {
    borderRadius: 0,
    padding: "0 30px",
  },
  accordionColor: {
    backgroundColor: Colors.grayLight,
  },
  accordionColorHover: {
    backgroundColor: Shades.gray30,
  },
  spacer: {
    width: 30,
  },
  spacerDetails: {
    width: 85,
  },
  accordionSummary: {
    paddingTop: 0,
    paddingLeft: 15,
    paddingRight: 15,
    backgroundColor: "unset",
    [theme.breakpoints.down("xs")]: {
      "& p": {
        textAlign: "left",
      },
    },
  },
  mobileTextAlign: {
    textAlign: "left",
  },
  expandIcon: {
    color: Colors.greenLight,
    height: 30,
    width: 30,
  },
  expandedIcon: {
    height: 30,
    width: 30,
    transform: "rotate(90deg)",
    color: Colors.greenLight,
  },
  divider: {
    marginTop: 10,
    marginBottom: 10,
  },
  dividerFirst: {
    marginTop: 0,
    marginBottom: 10,
  },
  lineHeightExpanded: {
    lineHeight: "40px",
    [theme.breakpoints.down("xs")]: {
      lineHeight: "20px",
    },
  },
  lineHeight: {
    lineHeight: "20px",
  },
  productDescription: {
    [theme.breakpoints.down("xs")]: {
      textAlign: "left",
    },
  },
  container: {
    height: "100%",
  },
  mobileAccordionHeader: {
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 35px)",
    },
  },
  packageContainer: {
    paddingLeft: "30px",
    paddingRight: "30px",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "15px",
      paddingRight: "15px",
    },
  },
  checkboxMin: {
    minWidth: 40,
  },
  checkboxPlaceholderMin: {
    minWidth: 48,
  },
}));

interface ICartOrderAccordionProps {
  order: ICfmOrderX;
  onSelect: (checked: boolean, id: number) => void;
  selectedOrderIds: number[];
}

export const CartOrderAccordion: FC<ICartOrderAccordionProps> = (props) => {
  const { order, onSelect, selectedOrderIds } = props;
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const [isHovering, setIsHovering] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const productItem = (order.items ?? []).find((item) => item.product);
  const packageItems = getPackagesFromItems(order.items);

  const AccordionSummaryNoExpandIconRotate = withStyles((theme) => ({
    root: {
      padding: "15px 30px",
      [theme.breakpoints.down("xs")]: {
        padding: "15px",
        flexDirection: "column-reverse",
      },
    },
    expandIcon: {
      transform: "none !important",
      minHeight: 68,
      [theme.breakpoints.down("xs")]: {
        position: "absolute",
        top: 2,
        right: 15,
      },
    },
    content: {
      width: "100%",
      "&.Mui-expanded": {
        margin: 0,
      },
    },
  }))(AccordionSummary);

  const handleSelectOrderCheckbox = (checked: boolean) => {
    setIsExpanded(isExpanded);
    onSelect(checked, order.id);
  };

  const getProductName = () => {
    if (!productItem) return "";
    const product = productItem.product;
    return `${t("cart.product.label")} ${product?.articleNumber ?? ""} ${ARTICLE_SEPARATOR} ${product?.name ?? ""}`;
  };

  const getProductMiscText = () => {
    if (!productItem) return "";
    const product = productItem.product;
    let text = "";
    if (product?.avvCode) {
      text = t("cart.product.avvCode", { code: product.avvCode });
    }

    if (productItem.targetGrossWeightKg) {
      text = `${text} ${t("cart.product.grossWeight", { quantity: productItem.targetGrossWeightKg })}`;
    }

    if (productItem.targetNetWeightKg) {
      text = `${text} ${t("cart.product.weight", { quantity: productItem.targetNetWeightKg })}`;
    }

    if (productItem.product?.articleType === CfmProductArticleType.SingleOrderProduct) {
      text = `${t("cart.singleOrderProduct.quantity", { quantity: productItem.quantity })}`;
    }

    return text;
  };

  const getEditButton = (): ReactNode => {
    return (
      <Box>
        <EditIconButton
          onClick={() => {
            const url = replaceUriParameter(AvailableCfmRoutes.OrderXEditPending, "id", order.id);
            history.push(url);
          }}
        />
      </Box>
    );
  };

  // Underlying component exposes this type
  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleExpandChange = (event: ChangeEvent<{}>, expanded: boolean) => {
    setIsExpanded(expanded);
  };

  const displayMiscText = useMemo(() => {
    return isExpanded;
  }, [isExpanded]);

  return (
    <Accordion
      elevation={0}
      expanded={isExpanded}
      className={classes.accordion}
      onChange={handleExpandChange}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <AccordionSummaryNoExpandIconRotate
        expandIcon={
          productItem ? (
            <ArrowForwardIosOutlined classes={{ root: isExpanded ? classes.expandedIcon : classes.expandIcon }} />
          ) : (
            <></>
          )
        }
        className={classNames(classes.summaryContainer, {
          [classes.accordionColorHover]: isHovering && !isExpanded,
        })}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className={classes.mobileAccordionHeader}
        >
          <Grid item xs={11} container direction="row" spacing={1}>
            <Grid item className={classes.checkboxMin}>
              <Box display={"flex"} alignItems={"center"} className={classes.container}>
                <OrderCheckbox
                  label={""}
                  onChange={handleSelectOrderCheckbox}
                  checked={selectedOrderIds.includes(order.id)}
                  size="medium"
                />
              </Box>
            </Grid>
            <Grid item xs container direction="column">
              <Grid item>
                <Typography variant="body1">{getProductName()}</Typography>
              </Grid>
              {displayMiscText && productItem && (
                <Grid item>
                  <Typography variant="body2">{getProductMiscText()}</Typography>
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid item xs={1}>
            {getEditButton()}
          </Grid>
        </Grid>
      </AccordionSummaryNoExpandIconRotate>
      <AccordionDetails
        className={classNames(classes.packageContainer, {
          [classes.accordionColorHover]: isHovering,
        })}
        classes={{ root: classes.accordionSummary }}
      >
        <Grid container direction="row">
          <Grid item className={classes.checkboxPlaceholderMin} />
          <Grid item xs>
            <Grid container direction="column" spacing={0}>
              {packageItems.map((packageItem, index) => (
                <>
                  <Grid item lg={12}>
                    <Divider classes={{ root: index === 0 ? classes.dividerFirst : classes.divider }} />
                  </Grid>
                  <OrderXCardPackageInformation
                    quantityToCollect={packageItem.quantityToCollect}
                    quantityToDeliver={packageItem.quantityToDeliver}
                    packageName={packageItem.packageName}
                    id={packageItem.id}
                  />
                </>
              ))}
              <Grid item lg={12}>
                <Divider
                  classes={{
                    root:
                      productItem?.product?.articleType === CfmProductArticleType.Product
                        ? classes.divider
                        : classes.dividerFirst,
                  }}
                />
              </Grid>
              <CartOrderInfoRow
                requestedPickupDate={order.requestedPickupDate}
                purchaseOrderNumber={order.purchaseOrderNumber}
                orderComment={order.orderComment}
              />
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
