import { RestEndpoints } from "api/endpoints";
import { PomConfigurationConverter } from "put-on-market/domain/converter/configuration/pom-configuration.converter";
import { IPomConfiguration } from "put-on-market/domain/models/configuration/pom-configutation";
import { IPomConfigurationListModel } from "put-on-market/repositories/models/configuration/pom-configuration-model";
import { PomQueryKeys } from "put-on-market/repositories/pom-query-keys";
import { useMutation } from "react-query";
import getInstance from "utils/rest";


type QueryErrorHandler =
  | ((
  error: unknown,
  variables: {
    configurationInput: IPomConfigurationListModel;
  },
  context: unknown,
) => void | Promise<unknown>)
  | undefined;
type QuerySuccessHandler =
  | ((
  data: IPomConfiguration,
  variables: {
    configurationInput: IPomConfigurationListModel;
  },
  context: unknown,
) => void | Promise<unknown>)
  | undefined;

const updateConfiguration = async (
  configurationInput: IPomConfigurationListModel,
): Promise<IPomConfiguration> => {
  const url = RestEndpoints.UpdatePomConfigurationList;
  const { data } = await getInstance().patch<IPomConfigurationListModel>(url, configurationInput);

  return PomConfigurationConverter.toDomain(data);
};

export const useUpdateConfigurationQuery = (handleError: QueryErrorHandler, handleSuccess: QuerySuccessHandler) => {
  return useMutation(
    [PomQueryKeys.UpdateConfiguration],
    ({
       configurationInput,
     }: {
      configurationInput: IPomConfigurationListModel;
    }) => updateConfiguration(configurationInput),
    {
      onError: handleError,
      onSuccess: handleSuccess,
    },
  );
};
