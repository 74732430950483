import { IPomConfiguration } from "put-on-market/domain/models/configuration/pom-configutation";
import React, { createContext, useContext } from "react";
import useAsyncEffect from "use-async-effect";
import { useGetConfigurationQuery } from "../../../put-on-market/repositories/queries/configuration/get-configuration-query";
import { useGetTakeBackSystemsQuery } from "../../../put-on-market/repositories/queries/take-back-system/get-take-back-systems.query";

interface IPomConfigurationProvider {
  isLoading: boolean;
  configuration: IPomConfiguration | undefined;
}

export const PomConfigurationContext = createContext<IPomConfigurationProvider>({} as IPomConfigurationProvider);

export const usePomConfigurationContext = () => {
  return useContext(PomConfigurationContext);
};

const usePomConfigurationProvider = (): IPomConfigurationProvider => {
  const { data: configuration, isLoading: configurationLoading, refetch: getConfiguration } = useGetConfigurationQuery();
  const { data: takeBackSystems, isLoading: takeBackSystemsLoading, refetch: getTakeBackSystems } = useGetTakeBackSystemsQuery();

  useAsyncEffect(async () => {
    await getConfiguration();
    await getTakeBackSystems();
  }, []);

  const sortedTakeBackSystems = takeBackSystems?.slice().sort((a, b) => a.name.localeCompare(b.name));
  const combinedConfiguration = configuration ? { ...configuration, takeBackSystems: sortedTakeBackSystems } : undefined;

  return {
    isLoading: configurationLoading || takeBackSystemsLoading,
    configuration: combinedConfiguration,
  };
};

export const PomConfigurationProvider: React.FC = (props) => {
  const value = usePomConfigurationProvider();
  return <PomConfigurationContext.Provider value={value}>{props.children}
  </PomConfigurationContext.Provider>;
};
