import { RestEndpoints } from "api/endpoints";
import { IPomProductTakeBackSystemListModel } from "put-on-market/repositories/models/products/pom-product-take-back-system.model";
import { IPomProductTakeBackSystem } from "put-on-market/domain/models/takeback-system";
import { useMutation } from "react-query";
import getInstance from "utils/rest";
import { PomTakeBackSystemConverter } from "../../../../domain/converter/pom-takeback-system.converter";

type QueryErrorHandler =
  | ((
  error: unknown,
  variables: {
    takeBackSystemsInput: IPomProductTakeBackSystemListModel;
  },
  context: unknown,
) => void | Promise<unknown>)
  | undefined;
type QuerySuccessHandler =
  | ((
  data: IPomProductTakeBackSystem[],
  variables: {
    takeBackSystemsInput: IPomProductTakeBackSystemListModel;
  },
  context: unknown,
) => void | Promise<unknown>)
  | undefined;

const updateTakeBackSystems = async (
  takeBackSystemsInput: IPomProductTakeBackSystemListModel,
): Promise<IPomProductTakeBackSystem[]> => {
  const url = RestEndpoints.UpdatePomProductTakeBackSystems;
  const { data } = await getInstance().patch<IPomProductTakeBackSystemListModel>(url, takeBackSystemsInput);

  return PomTakeBackSystemConverter.toDomainList(data);
};

export const useUpdateTakeBackSystemsQuery = (handleError: QueryErrorHandler, handleSuccess: QuerySuccessHandler) => {
  return useMutation(
    ['UpdateTakeBackSystems'],
    ({
       takeBackSystemsInput,
     }: {
      takeBackSystemsInput: IPomProductTakeBackSystemListModel;
    }) => updateTakeBackSystems(takeBackSystemsInput),
    {
      onError: handleError,
      onSuccess: handleSuccess,
    },
  );
};
