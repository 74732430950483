import {Grid, LinearProgress, makeStyles, Theme, Typography} from "@material-ui/core";
import React, {useEffect, VFC} from "react";
import {useTranslation} from "react-i18next";
import {
  PomConfigurationProvider,
  usePomConfigurationContext
} from "../../components/pom-configuration-form/pom-configuration.provider";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {
  PomConfigurationConverter
} from "../../../put-on-market/domain/converter/configuration/pom-configuration.converter";
import {
  useUpdateConfigurationQuery
} from "../../../put-on-market/repositories/queries/mutations/configuration/update-configuration.query";
import {IPomConfiguration} from "../../../put-on-market/domain/models/configuration/pom-configutation";
import {PomQueryKeys} from "../../../put-on-market/repositories/pom-query-keys";
import {useSnackbar} from "notistack";
import {useQueryClient} from "react-query";
import {FormField} from "../../../shared/components/form/form-field.component";
import {FormDatepickerField} from "../../../shared/components/form/fields/form-datepicker.field";
import {positiveNumberOnly} from "../../../utils/validationHelper";
import {FormTextField} from "../../../shared/components/form/fields/form-text.field";
import {AppDialogButtonRow} from "../../../shared/components/dialog/app-dialog-button-row.component";
import {PomTakeBackSystemConverter} from "../../../put-on-market/domain/converter/pom-takeback-system.converter";
import {
  useUpdateTakeBackSystemsQuery
} from "../../../put-on-market/repositories/queries/mutations/take-back-system/update-take-back-systems.query";

const useStyles = makeStyles((theme: Theme) => ({
  heading: {
    marginTop: 30,
    marginBottom: 10,
  },
}));

export interface IPomConfigurationFormInputs {
  announcementGracePeriod: string;
  announcementReductionAllowedPeriodStart: Date;
  announcementReductionAllowedPeriodEnd: Date;
}

export const PomConfigurationForm: VFC = () => {
  const {t} = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const classes = useStyles();
  const {isLoading, configuration} = usePomConfigurationContext();
  const {
    register,
    handleSubmit: onSubmit,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm<IPomConfiguration>();

  useEffect(() => {
    if (configuration) {
      reset(configuration);
    }
  }, [configuration, reset]);

  const handleUpdateError = () => {
    enqueueSnackbar(t("configuration.pom.error.update"), { variant: "error" });
  };

  const handleUpdateSuccess = () => {
    queryClient.invalidateQueries(PomQueryKeys.GetConfiguration);
    queryClient.invalidateQueries(PomQueryKeys.GetTakeBackSystems);
    enqueueSnackbar(t("general.successfull_saved"), { variant: "success" });
    reset();
  };

  const { isLoading: isUpdatingConfiguration, mutateAsync: updateConfiguration } = useUpdateConfigurationQuery(
      ()=>{}, ()=>{}
  );

  const { isLoading: isUpdatingTakeBackSystems, mutateAsync: updateTakeBackSystems } = useUpdateTakeBackSystemsQuery(
      ()=>{}, ()=>{}
  )

  const announcementReductionAllowedPeriodStart = watch("announcementReductionAllowedPeriodStart");
  const announcementReductionAllowedPeriodEnd = watch("announcementReductionAllowedPeriodEnd");

  const handleSubmit: SubmitHandler<IPomConfiguration> = (inputs) => async (ignoreDuplicateName: boolean) => {
    try {
      const configurationRequest = PomConfigurationConverter.toModel(inputs);
      await updateConfiguration({ configurationInput: configurationRequest });
      if (inputs.takeBackSystems) {
        const takeBackSystemsRequest = PomTakeBackSystemConverter.toModelList(inputs.takeBackSystems);
        await updateTakeBackSystems({ takeBackSystemsInput: takeBackSystemsRequest });
      }
      handleUpdateSuccess();
    } catch (error) {
      handleUpdateError();
    }
  };

  if (isLoading) {
    return <Typography>{t("loading")}</Typography>;
  }

  if (!configuration) {
    return <Typography>{t("configuration.pom.notFound")}</Typography>;
  }

  if (isUpdatingConfiguration || isUpdatingTakeBackSystems) {
    return <LinearProgress />;
  }

  return (
    <form onSubmit={onSubmit((inputs) => handleSubmit(inputs)(false))}>
      <Grid container direction={"row"} spacing={4} md={6}>
        <Grid item className={classes.heading} md={12}>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item md={12}>
              <Typography variant="h4">{t("configuration.pom.heading.title")}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12}>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item md={12}>
              <Typography variant="h5">{t("configuration.pom.heading.announcement_reduction_period")}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <FormField md={6}>
          <FormDatepickerField
            hasError={Boolean(errors["announcementReductionAllowedPeriodStart"])}
            control={control}
            label={t("configuration.pom.form.announcement_reduction_allowed_period_start")}
            required={true}
            maxDate={announcementReductionAllowedPeriodEnd ? announcementReductionAllowedPeriodEnd : undefined}
            {...register("announcementReductionAllowedPeriodStart", {
              required: true,
            })}
          />
        </FormField>
        <FormField md={6}>
          <FormDatepickerField
            hasError={Boolean(errors["announcementReductionAllowedPeriodEnd"])}
            control={control}
            label={t("configuration.pom.form.announcement_reduction_allowed_period_end")}
            required={true}
            minDate={announcementReductionAllowedPeriodStart ? announcementReductionAllowedPeriodStart : undefined}
            {...register("announcementReductionAllowedPeriodEnd", {
              required: true,
            })}
          />
        </FormField>
        <Grid item md={12}>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item md={12}>
              <Typography variant="h5">{t("configuration.pom.heading.announcement_grace_period")}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <FormField md={6}>
          <FormTextField
            hasError={Boolean(errors["announcementGracePeriod"])}
            label={t("configuration.pom.form.announcement_grace_period")}
            type="number"
            name={"announcementGracePeriod"}
            control={control}
            rules={{
              required: true,
              validate: (value) =>
                positiveNumberOnly(value, t("pom.configuration.error.announcement_grace_period"), true),
            }}
          />
        </FormField>
        <Grid item md={6}/>
        <Grid item md={12}>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item md={12}>
              <Typography variant="h5">{t("configuration.pom.heading.announcement_reminder_due_date")}</Typography>
            </Grid>
          </Grid>
        </Grid>
        {configuration.takeBackSystems?.map((system, index) => (
          <FormField key={system.id} md={3}>
            <Controller
              name={`takeBackSystems.${index}.reminderNotificationDueDay`}
              control={control}
              render={({field}) => (
                <FormTextField
                  {...field}
                  label={`${system.name}`}
                  type="number"
                  control={control}
                  hasError={Boolean(errors.takeBackSystems?.[index]?.reminderNotificationDueDay)}
                  inputProps={{ inputProps: { min: 1, max: 31 } } }
                />
              )}
            />
          </FormField>
        ))}
      </Grid>
      <AppDialogButtonRow
        isLoading={isLoading}
        acceptTextOverride={t("general.save.text")}
        onAcceptClick={() => {
        }}
        type="submit"
        alignButtons="space-between"
      />
    </form>
  );
};

export const PomConfigurationPage: VFC = () => {
  return (
    <PomConfigurationProvider>
      <PomConfigurationForm/>
    </PomConfigurationProvider>
  );
};
