import { ConfigIcon } from "../../../components/Primitives/Icons";
import { AvailableConfiguratorRoutes } from "../../../utils/constants";
import { escapeParam, INavigationItem } from "./navigation-item";

export default function getConfigAdminNavItems(): INavigationItem[] {
  return [
    {
      label: "navigation.configuration.configurator",
      icon: ConfigIcon,
      to: "/portal/config/",
      visible: true,
      isParent: true,
      content: [
        {
          label: "navigation.configuration.overview",
          description: "navigation.configuration.overview",
          to: AvailableConfiguratorRoutes.ProductOverview,
          visible: true,
        },
        {
          label: "navigation.configuration.createProductConfig",
          description: "navigation.configuration.createProductConfig",
          to: AvailableConfiguratorRoutes.CreateProductConfig,
          visible: true,
        },
        {
          label: "navigation.configuration.createPackageConfig",
          description: "navigation.configuration.createPackageConfig",
          to: AvailableConfiguratorRoutes.PackageOverview,
          visible: true,
          invisibleChildrenUrls: [AvailableConfiguratorRoutes.CreateProductPackageConfig],
        },
        {
          label: "navigation.configuration.routing",
          description: "navigation.configuration.routing",
          to: AvailableConfiguratorRoutes.ProductRoutingOverview,
          visible: true,
          invisibleChildrenUrls: [
            AvailableConfiguratorRoutes.ProductsForRouting,
            AvailableConfiguratorRoutes.CreateProductRouting,
            AvailableConfiguratorRoutes.EditProductRouting,
          ],
        },
        {
          label: "",
          to: AvailableConfiguratorRoutes.ProductsForRouting,
          visible: false,
        },
        {
          label: "",
          to: AvailableConfiguratorRoutes.CreateProductRouting,
          visible: false,
        },
        {
          label: "",
          to: AvailableConfiguratorRoutes.EditProductRouting,
          visible: false,
        },
        {
          label: "",
          to: AvailableConfiguratorRoutes.CreateProductPackageConfig,
          visible: false,
        },
        {
          label: "navigation.configuration.responses",
          description: "navigation.configuration.responses",
          to: AvailableConfiguratorRoutes.ProductResponseOverview,
          visible: true,
          invisibleChildrenUrls: [
            escapeParam(AvailableConfiguratorRoutes.CreateResponseConfig, [":ids", ":assignmentIds"]),
          ],
        },
        {
          label: "",
          to: AvailableConfiguratorRoutes.CreateResponseConfig,
          visible: false,
        },
        {
          label: "navigation.configuration.responseFields",
          description: "navigation.configuration.responseFields",
          to: AvailableConfiguratorRoutes.ProductResponseFieldConfig,
          visible: true,
        },
        {
          label: "navigation.configuration.pom",
          description: "navigation.configuration.pom",
          to: AvailableConfiguratorRoutes.PomConfiguration,
          visible: true,
        },
      ],
    },
  ];
}
