import {
  IPomProductTakeBackSystemListModel,
  IPomProductTakeBackSystemModel
} from "../../repositories/models/products/pom-product-take-back-system.model";
import { IPomProductTakeBackSystem } from "../models/takeback-system";

export class PomTakeBackSystemConverter {
  public static toDomain(model: IPomProductTakeBackSystemModel): IPomProductTakeBackSystem {
    return {
      id: model.id,
      name: model.name,
      internalNumber: model.internalNumber,
      reminderNotificationDueDay: String(model.reminderNotificationDueDay),
    };
  }

  public static toModel(domain: IPomProductTakeBackSystem): IPomProductTakeBackSystemModel {
    return {
      id: domain.id,
      name: domain.name,
      internalNumber: domain.internalNumber,
      reminderNotificationDueDay: Number(domain.reminderNotificationDueDay),
    };
  }

  public static toModelList(domain: IPomProductTakeBackSystem[]): IPomProductTakeBackSystemListModel {
    return {
      items: domain.map(this.toModel),
    };
  }

  public static toDomainList(model: IPomProductTakeBackSystemListModel): IPomProductTakeBackSystem[] {
    return model.items.map(this.toDomain);
  }
}
