import { FC, useState } from "react";
import { ManualUnsavedDataPrompt } from "../../../../components/Routes/manual-unsaved-data-prompt";
import { useUnsavedDataContext } from "../../../../providers/App/unsaved-data-context";
import { IPomGroupContract } from "../../../../shared/domain/group/group-contract";
import { PomAnnouncementDatePickerProvider } from "../../../components/announcement-date-picker/pom-announcement-date-picker.provider";
import { PomAnnouncementHeaderComponent } from "../../../components/announcement-header/pom-announcement-header.component";
import { PomAnnouncementTable } from "../../../components/announcement-table/table/pom-announcement-table.component";
import { PomProductTable } from "../../../components/product-table/pom-product-table.component";
import { PomProductTableProvider } from "../../../components/product-table/pom-product-table.provider";
import { PomAnnouncementPeriod } from "../../../repositories/models/announcements/pom-announcement.period";
import { usePomAnnouncementContext } from "../pom-announcement.provider";
import { PomConfigurationProvider } from "../../../../configurator/components/pom-configuration-form/pom-configuration.provider";

interface IPomAnnouncementContentProps {
  period: PomAnnouncementPeriod | undefined;
  contract: IPomGroupContract | undefined;
  handleOpenTakeBackSystemDialog: VoidFunction;
}

export const PomAnnouncementContent: FC<IPomAnnouncementContentProps> = (props) => {
  const { period, contract, handleOpenTakeBackSystemDialog } = props;
  const { handleAnnouncementDateChanged, onProductAdded, products, changedProducts, latestAnnouncementDate } =
    usePomAnnouncementContext();
  const { hasUnsavedData, setHasUnsavedData } = useUnsavedDataContext();

  const [showUnsavedDialog, setShowUnsavedDialog] = useState<{
    open: boolean;
    announcementDate?: Date;
    latestAnnouncementDate?: Date;
  }>({ open: false });

  const UnsavedDataPrompt: FC<{
    handleAnnouncementDateChanged: (date: Date, latestAnnouncementDate?: Date) => void;
  }> = ({ handleAnnouncementDateChanged }) => {
    return (
      <ManualUnsavedDataPrompt
        open={showUnsavedDialog.open}
        onOK={() => {
          if (showUnsavedDialog.announcementDate) {
            handleAnnouncementDateChanged(showUnsavedDialog.announcementDate, showUnsavedDialog.latestAnnouncementDate);
          }
          setHasUnsavedData(false);
          setShowUnsavedDialog({ open: false });
          return true;
        }}
        onCancel={() => {
          setShowUnsavedDialog({ open: false });
          return false;
        }}
      />
    );
  };

  return (
    <>
      {period && (
        <>
          <UnsavedDataPrompt handleAnnouncementDateChanged={handleAnnouncementDateChanged} />
          <PomAnnouncementDatePickerProvider
            period={period}
            onAnnouncementChanged={(announcementDate) => {
              if (hasUnsavedData) {
                setShowUnsavedDialog({
                  open: true,
                  announcementDate: announcementDate,
                  latestAnnouncementDate: latestAnnouncementDate,
                });
                return;
              }

              handleAnnouncementDateChanged(announcementDate);
            }}
          >
            <PomConfigurationProvider>
              <PomAnnouncementHeaderComponent
                period={period}
                contract={contract}
                openTakeBackSystemDialog={handleOpenTakeBackSystemDialog}
                className="my-4"
              />
            </PomConfigurationProvider>
          </PomAnnouncementDatePickerProvider>
        </>
      )}

      <PomAnnouncementTable className="mb-5" />

      <PomProductTableProvider selectedProducts={[...(products ?? []), ...(changedProducts ?? [])]}>
        <PomProductTable onProductAdded={onProductAdded} />
      </PomProductTableProvider>
      <div className="mb-4" />
    </>
  );
};
