export enum PomQueryKeys {
  ExportExcelAnnouncements = "ExportExcelAnnouncements",
  GetFirstPomAnnouncement = "GetFirstPomAnnouncement",
  GetPomMainContactsOfCurrentGroup = "GetPomMainContactsOfCurrentGroup",

  // Products
  GetProducts = "GetProducts",
  GetProductTypes = "GetProductTypes",

  // Manufacturers
  GetManufacturer = "GetManufacturer",
  UpdateManufacturer = "UpdateManufacturer",
  CreateManufacturer = "CreateManufacturer",

  // Agency
  GetAgencyCompanies = "GetAgencyCompanies",

  // Batt G
  GetGrsBatteryTypes = "GetGrsBatteryTypes",
  GetBrand = "GetBrand",
  GetBrands = "GetBrands",
  GetRegistrationOfCurrentGroup = "GetRegistrationOfCurrentGroup",
  UpdateBrand = "UpdateBrand",
  CreateBrand = "CreateBrand",
  UpdateRegistrationOfCurrentGroup = "UpdateRegistrationOfCurrentGroup",

  // Announcements
  ExtractAnnouncementTemplateData = "ExtractAnnouncementTemplateData",
  PatchAnnouncement = "PatchAnnouncement",
  CreateAnnouncement = "CreateAnnouncement",
  GetAnnouncementTemplate = "GetAnnouncementTemplate",
  ExportAnnouncement = "ExportAnnouncement",
  GetAnnouncements = "GetAnnouncements",
  GetAnnouncement = "GetAnnouncement",
  GetLatestAnnouncement = "GetLatestAnnouncement",

  // Configuration
  GetConfiguration = "GetConfiguration",
  UpdateConfiguration = "UpdateConfiguration",

  // Take Back Systems
  GetTakeBackSystems = "GetTakeBackSystems",
  UpdateTakeBackSystem = "UpdateTakeBackSystem",
}
