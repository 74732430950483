import { useQuery } from "react-query";
import { RestEndpoints } from "../../../../api/endpoints";
import getInstance from "../../../../utils/rest";
import { PomQueryKeys } from "../../pom-query-keys";
import { ReactQueryConfig } from "../../../../config/react-query-config";
import { IPomConfigurationListModel } from "../../models/configuration/pom-configuration-model";
import { IPomConfiguration } from "../../../domain/models/configuration/pom-configutation";
import { PomConfigurationConverter } from "../../../domain/converter/configuration/pom-configuration.converter";

const getConfiguration = async (): Promise<IPomConfiguration> => {
  const { data } = await getInstance().get<IPomConfigurationListModel>(RestEndpoints.GetPomConfiguration);

  return PomConfigurationConverter.toDomain(data);
}

export const useGetConfigurationQuery = () => {
  return useQuery([PomQueryKeys.GetConfiguration], () => getConfiguration(), {
    staleTime: ReactQueryConfig.staleTime,
  });
};
