import { IPomConfiguration } from "../../models/configuration/pom-configutation";
import { IPomConfigurationListModel, IPomConfigurationModel } from "../../../repositories/models/configuration/pom-configuration-model";
import dayjs from "dayjs";

export class PomConfigurationConverter {
  public static toModel(configuration: IPomConfiguration): IPomConfigurationListModel {
    const entries: IPomConfigurationModel[] = [
      { key: "announcementGracePeriod", value: configuration.announcementGracePeriod.toString() },
      { key: "announcementReductionAllowedPeriodStart", value: dayjs(configuration.announcementReductionAllowedPeriodStart).format("YYYY-MM-DD") },
      { key: "announcementReductionAllowedPeriodEnd", value: dayjs(configuration.announcementReductionAllowedPeriodEnd).format("YYYY-MM-DD") },
    ];

    return { entries };
  }

  public static toDomain(configurationListModel: IPomConfigurationListModel): IPomConfiguration {
    const configuration = configurationListModel.entries.reduce((record, entry) => {
      record[entry.key] = entry.value;
      return record;
    }, {} as Record<string, string>);

    return {
      announcementGracePeriod: parseInt(configuration.announcementGracePeriod),
      announcementReductionAllowedPeriodStart: dayjs(configuration.announcementReductionAllowedPeriodStart, "YYYY-MM-DD").toDate(),
      announcementReductionAllowedPeriodEnd: dayjs(configuration.announcementReductionAllowedPeriodEnd, "YYYY-MM-DD").toDate(),
    };
  }
}
