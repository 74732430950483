export enum CfmQueryKeys {
  // Order
  DeletePendingOrders = "DeletePendingOrders",

  // OrderX
  GetOrdersXWithFilterInfinite = "GetOrdersXWithFilterInfinite",
  GetOrdersXWithFilter = "GetOrdersXWithFilter",
  GetOrderX = "GetOrderX",
  GetOrderXStatusCount = "GetOrderXCount",
  UpdateOrderXReadStatus = "UpdateOrderXReadStatus",
  GetOrderXActions = "GetOrderXActions",
  UpdateOrderX = "UpdateOrderX",
  BulkUpdateOrderX = "BulkUpdateOrderX",
  GetCfmOrderResponseReport = "GetCfmOrderResponseReport",
  GetOrderXHistory = "GetOrderHistory",
  GetOrderTickets = "GetOrderTickets",
  GetOrderXDeliveryReport = "GetOrderXDeliveryReport",
  GetOrderXBulkActions = "GetOrderXBulkActions",
  GetOrdersXExportByFilter = "GetOrdersXExportByFilter",
  GetOrdersXExportByIds = "GetOrdersXExportByIds",
  GetCancelationReasons = "GetCancelationReasons",
  GetOrderLogisticRouting = "GetOrderLogisticRouting",
  AddOrderFreshdeskRequest = "AddOrderFreshdeskRequest",
  GetOrderXStatusConfiguration = "GetOrderXStatusConfiguration",

  // Transfer Order
  TransferOrder = "TransferOrder",
  UpdateTransferOrder = "UpdateTransferOrder",
  GetTransferOrders = "GetTransferOrders",
  ExportTransferOrders = "ExportTransferOrders",
  GetTransferOrdersNotifiedWeight = "GetTransferOrdersNotifiedWeight",
  GetTransferOrderActions = "GetTransferOrderActions",
  GetTransferOrderActionsForProduct = "GetTransferOrderActionsForProduct",

  // Truckload
  GetServiceDatesForTruckload = "GetServiceDatesForTruckload",
  CreateTruckload = "CreateTruckload",
  Truckloads = "Truckloads",
  Truckload = "Truckload",
  TruckloadAvailableStatus = "TruckloadAvailableStatus",
  CancelTruckload = "CancelTruckload",
  UpdateTruckloadOrders = "UpdateTruckloadOrders",

  // Address
  GetProductAddresses = "GetProductAddresses",
  GetAddresses = "GetAddresses",
  GetLogisticsAddressForAccepting = "GetLogisticsAddressForAccepting",

  // Products
  GetProductById = "GetProductById",
  GetProductCategories = "GetProductCategories",
  GetProductStates = "GetProductStates",
  GetProductFractions = "GetProductFractions",
  GetProductTypes = "GetProductTypes",
  GetAllProducts = "GetAllProducts",
  GetProductsByIds = "GetProductsByIds",
  GetAllProductsInfinite = "GetAllProductsInfinite",
  CreateNewProducts = "CreateNewProducts",
  UpdateProductName = "UpdateProductName",
  GetProductCount = "GetProductCount",
  GetWarehouseItems = "GetWarehouseItems",
  GetWarehouseProducts = "GetWarehouseProducts",
  GetWarehouseTotalWeight = "GetWarehouseTotalWeight",

  // Routing
  UploadPostalsForCreateAssignments = "UploadPostalsForCreateAssignments",
  UploadPostalsForCreateAssignmentsTemplate = "UploadPostalsForCreateAssignmentsTemplate",
  CreateProductRoutingAssignments = "CreateProductRoutingAssignments",
  DeleteProductRoutingAssignments = "DeleteProductRoutingAssignments",
  GetProductRoutingAssignment = "GetProductRoutingAssignment",

  // Packages
  GetPackagesForProduct = "GetPackagesForProduct",
  GetPackages = "GetPackages",
  CreateProductPackageConfig = "CreateProductPackageConfig",

  // ResponseFields
  CreateNewResponseField = "CreateNewResponseField",
  UpdateResponseField = "UpdateResponseField",
  GetAllResponseFieldsInfinite = "GetAllResponseFieldsInfinite",
  GetResponseFields = "GetResponseFields",

  // ResponseAssignments
  GetResponseAssignments = "GetResponseAssignments",
  GetResponseAssignmentByIds = "GetResponseAssignmentByIds",
}
