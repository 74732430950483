import React, { VFC } from "react";
import { Redirect, Switch } from "react-router-dom";
import { GroupType } from "../shared/domain/group/group-type";
import { AuthenticatedRoute } from "../utils/AuthenticatedRoute";
import { AvailableConfiguratorRoutes, AvailableSharedRoutes } from "../utils/constants";
import { CreateProductPackageConfigPage } from "./pages/package/create-product-package-config.page";
import { PackageConfigurationOverviewPage } from "./pages/package/package-configuration-overview.page";
import { ProductResponseFieldOverview } from "./pages/product-response-field/product-response-field-overview.page";
import { CreateProductResponsePage } from "./pages/product-response/create-product-response.page";
import { ExistingProductResponsePage } from "./pages/product-response/existing-product-response.page";
import { CreateProductRoutingPage } from "./pages/product-routing/create-product-routing.page";
import { EditProductRoutingPage } from "./pages/product-routing/edit-product-routing.page";
import { ExistingProductRoutingPage } from "./pages/product-routing/existing-product-routing.page";
import { ProductsForRoutingPage } from "./pages/product-routing/products-for-routing.page";
import { CreateProductPage } from "./pages/product/create-product.page";
import { ProductOverviewPage } from "./pages/product/product-overview.page";
import { PomConfigurationPage } from "./pages/pom-configuration/pom-configuration.page";

export const ConfigurationRoutes: VFC = () => {
  return (
    <Switch>
      <AuthenticatedRoute
        path={AvailableConfiguratorRoutes.ProductOverview}
        allowedRoles={[GroupType.Admin]}
        component={ProductOverviewPage}
        exact
      />
      <AuthenticatedRoute
        path={AvailableConfiguratorRoutes.CreateProductConfig}
        allowedRoles={[GroupType.Admin]}
        component={CreateProductPage}
        exact
      />
      <AuthenticatedRoute
        path={AvailableConfiguratorRoutes.PackageOverview}
        allowedRoles={[GroupType.Admin]}
        component={PackageConfigurationOverviewPage}
        exact
      />
      <AuthenticatedRoute
        path={AvailableConfiguratorRoutes.CreateProductPackageConfig}
        allowedRoles={[GroupType.Admin]}
        component={CreateProductPackageConfigPage}
        exact
      />
      <AuthenticatedRoute
        path={AvailableConfiguratorRoutes.ProductRoutingOverview}
        allowedRoles={[GroupType.Admin]}
        component={ExistingProductRoutingPage}
        exact
      />
      <AuthenticatedRoute
        path={AvailableConfiguratorRoutes.ProductsForRouting}
        allowedRoles={[GroupType.Admin]}
        component={ProductsForRoutingPage}
        exact
      />
      <AuthenticatedRoute
        path={AvailableConfiguratorRoutes.CreateProductRouting}
        allowedRoles={[GroupType.Admin]}
        component={CreateProductRoutingPage}
        exact
      />
      <AuthenticatedRoute
        path={AvailableConfiguratorRoutes.EditProductRouting}
        allowedRoles={[GroupType.Admin]}
        component={EditProductRoutingPage}
        exact
      />
      <AuthenticatedRoute
        path={AvailableConfiguratorRoutes.ProductResponseOverview}
        allowedRoles={[GroupType.Admin]}
        component={ExistingProductResponsePage}
        exact
      />
      <AuthenticatedRoute
        path={AvailableConfiguratorRoutes.CreateResponseConfig}
        allowedRoles={[GroupType.Admin]}
        component={CreateProductResponsePage}
        exact
      />
      <AuthenticatedRoute
        path={AvailableConfiguratorRoutes.ProductResponseFieldConfig}
        allowedRoles={[GroupType.Admin]}
        component={ProductResponseFieldOverview}
        exact
      />
      <AuthenticatedRoute
        path={AvailableConfiguratorRoutes.PomConfiguration}
        allowedRoles={[GroupType.Admin]}
        component={PomConfigurationPage}
        exact
      />
      <Redirect path={"*"} to={AvailableSharedRoutes.Portal} push />
    </Switch>
  );
};
