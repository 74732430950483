import { CfmServiceDate } from "../../collect-from-market/domain/order-x/actions/cfm-service-date";
import { CfmOrderXApiStatus } from "../../collect-from-market/domain/order-x/cfm-order-x.status";
import { TruckloadState } from "../../collect-from-market/domain/truckload/truckload-state";
import { OrderOfTruckloadSorting } from "../../collect-from-market/pages/order-x/order-truckload.provider";
import { ProductOverviewGeneralFilter } from "../../configurator/pages/product/components/product-overview.utils";
import {
  GroupHistoryAddressInfoColumn,
  GroupHistoryGroupContractInfoColumn,
  GroupHistoryGroupGroupTypeInfoColumn,
  GroupHistoryGroupInfoColumn,
  GroupHistoryPaymentInfoColumn,
  GroupHistoryUserInfoColumn,
} from "../../shared/domain/group/group-history-info-column";
import { GroupHistoryInformationType } from "../../shared/domain/group/group-history-information-type";
import { GroupContractFormMode } from "./../../shared/components/group/group-contract-form/group-contract-form.component";
import { GroupContractType } from "./../../shared/models/group/group-contract.model";
import { ShortcutType } from "./../../utils/shortcuts";

export const TRANSLATIONS_DE = {
  current_locale: "de-DE",
  app_name: "GRS Portal",
  states_filter: "Status",
  locations_filter: "Standort",
  products_filter: "Produkt",
  group_uuid_filter: "Kunde",
  maintainance: {
    title: "Wartungsarbeiten",
    description: "Aktuell werden Wartungsarbeiten am Portal durchgeführt, bitte versuchen Sie es später erneut",
  },
  dashboard: {
    text: "Dashboard",
    title: "Mein Start",
    greeting: "Hallo",
    statistic: {
      missingConfigs: "{{count}} unvollständige Konfigurationen",
      [ShortcutType.PackageConfig]: "{{count}} Artikel ohne Verpackungskonfiguration",
      [ShortcutType.RoutingConfig]: "{{count}} Artikel ohne Routingkonfiguration",
      [ShortcutType.ResponseConfig]: "{{count}} Artikel ohne Rückmeldekonfiguration",
      [ShortcutType.Cart]: "{{count}} Artikel im Warenkorb",
    },
    platform: {
      title: "Bitte wählen Sie aus:",
      cfm: "Batterieabholung",
      pom: "Inverkehrbringung von Batterien",
      configurator: "Konfigurator",
      shortcuts: {
        cfm: {
          new_order: "Neuer Auftrag",
          order_overview: "Auftragsübersicht",
          repeat_order: "Auftrag wiederholen",
          cart: "Warenkorb",
        },
        pom: {
          new_announcment: "Meldung einreichen",
          group_overview: "Meine Kontoverwaltung",
          manufactorer_overview: "Herstellerverzeichnis",
          base_data: "Stammdatenerfassung gem. § 4 BattG",
          brands: "Meine BattG Marken",
        },
        config: {
          overview: "Übersicht",
          product: "Artikel konfigurieren",
          package: "Verpackungen konfigurieren",
          routing: "Routings konfigurieren",
          response: "Rückmeldungen konfigurieren",
          pom: "POM-Konfiguration",
        },
      },
    },
    customerInfo: {
      title: "Verzeichnis der genehmigten Rücknahmesysteme für Geräte-Altbatterien",
      sectionGrsBasic: {
        title: "der Stiftung GRS Batterien",
        grsBasic: "Rücknahmesystem der Stiftung GRS Batterien (Kurzbezeichnung: GRS Basic)",
      },
      sectionGrsService: {
        title: "der Gemeinsames Rücknahmesystem Servicegesellschaft mbH (GRS Service GmbH)",
        consumer: "GRS Consumer",
        eMobility: "GRS eMobility",
        healthcare: "GRS Healthcare",
        powertools: "GRS Powertools",
      },
      sectionIndustry: {
        title: "Verzeichnis der Rücknahmesysteme für Industrie-Altbatterien",
        automation: "GRS Automation",
        storage: "GRS Storage",
        eMobility: "GRS eMobility",
      },
    },
  },
  serviceDates: {
    delivery: "Service Zustelldatum",
    pickup: "Service Abholdatum",
    recycling: "Service Recyclingdatum",
    treatment: "Service Sortierungsdatum",
  },
  cart: {
    header: "Warenkorb: Bestellung abschließen",
    deleteSelected: "Ausgewählte löschen",
    orderSelected: "Ausgewählte bestellen",
    noOrders: "Der Warenkorb ist leer.",
    customerAddress: "Leistungsort: {{name}}",
    requestedPickupDate: "Angefragtes Lieferdatum: {{date}}",
    orderNumber: "Bestellnummer/PO: {{orderNumber}}",
    orderComment: "Bestellkommentar: {{orderComment}}",
    product: {
      label: "Produkt:",
      avvCode: "AVV: {{code}}",
      articleCode: "Sach-Nr.: {{code}}",
      grossWeight: "Bruttogewicht: {{quantity}} kg",
      weight: "Nettogewicht: {{quantity}} kg",
    },
    singleOrderProduct: {
      quantity: "Menge: {{quantity}} Stk.",
    },
    package: { label: "Verpackung:" },
  },
  general: {
    reason: "Grund",
    change: "Ändern",
    target: "Ziel",
    new: "Neu",
    selectAll: "alle auswählen",
    deselectAll: "alle abwählen",
    dateFormat: "TT.MM.JJJJ",
    unit: "Stk.",
    scrollToTop: "Nach oben scrollen",
    error_occurred:
      'Es ist leider ein Fehler aufgetreten - Bitte senden Sie die Fehlermeldung an portal@grs-batterien.de - [{{errorCode}}: "{{errorMsg}}"]',
    grs_support: "Anfrage GRS Support",
    contact_grs: "Kontakt GRS",
    grsCopyright: "© 2022 GRS Service GmbH",
    logout: "Ausloggen",
    support: "Support",
    amount: "Menge",
    successfull_saved: "Änderungen erfolgreich gespeichert!",
    close: "Schließen",
    search: "Suche",
    name: { text: "Name" },
    send: "Senden",
    back: { text: "Zurück" },
    next: { text: "Weiter" },
    cart: { text: "Warenkorb" },
    in_cart: { text: "In den Warenkorb" },
    order: {
      text: "Bestellen",
      no_status: "Kein Status",
      order_id: "AuftragsNr.",
      comment: { text: "Bestellkommentar" },
      shopping_card: { text: "Ausgewählte bestellen" },
    },
    text: {
      details: "Details",
      close_all: "Alle schließen",
      close: "Schließen",
      open_all: "Alle öffnen",
      invoice_address: "Rechnungsanschrift",
      history: "Historie",
      logistic: "Logistiker",
      recycler: "Recycler",
      waste_producer: "Abfallerzeuger",
      new_waste_producer: "Neuer Abfallerzeuger",
      field: { only: { positive: { numbers: "Nur Positive Nummern" } } },
    },
    geo: {
      postal_code: { text: "PLZ" },
      location: { text: "Ort" },
      country: { text: "Land" },
      address: { text: "Adresse" },
      region: { text: "Region" },
      region_plz: { text: "Region/PLZ" },
      address_street: { text: "Straße" },
      building_number: { text: "Hausnummer" },
    },
    company: { name: { text: "Firmenname" }, department: { text: "Abteilung" } },
    grs_customer_number: { text: "GRS Kundennummer" },
    telephone_number: { text: "Telefonnummer" },
    email_contact_person: { text: "E-Mail" },
    email: {
      text: "E-Mail",
      notValid: "Eine gültige E-Mail Adresse ist erforderlich.",
      required: { error: "Bitte geben Sie eine gültige E-Mail-Adresse ein." },
    },
    attachment: { add: { text: "Anhang hinzufügen" } },
    count: { text: "Anzahl" },
    product: {
      text: "Produkt",
      categories: "Produkt Kategorie",
      fractions: "Produkt Fraktion",
      types: "Produkt Typ",
      states: "Produkt Zustand",
    },
    action: { text: "Aktion" },
    packaging: { text: "Verpackung" },
    type: { text: "Type" },
    state: { text: "Zustand" },
    save: { text: "Speichern" },
    confirm: "Bestätigen",
    cancel: { text: "Abbrechen" },
    continue: { text: "Weiter" },
    ok: { text: "OK" },
    remove: { text: "Entfernen", shopping_card: { text: "Ausgewählte löschen" } },
    delete: { text: "Löschen" },
    override: { text: "Überschreiben" },
    main: { text: "Haupt", recycler: "Haupt" },
    alternative: { text: "Alternativ" },
    mandatory_field: "Pflichtfeld",
    mandatory_field_with_description: "Pflichtfeld: {{description}}",
    form: { required_field_warning: { text: "Erforderlich" } },
    upload: { text: "Hochladen", requiredError: "Sie müssen eine Datei hochladen" },
    sum: "Summe",
    active: "Aktiv",
    inactive: "Inaktiv",
    user: {
      create_success: "Benutzer wurde erfolgreich erstellt!",
      data: "Benutzer erstellen",
      create: { message: "Der Benutzer wurde erfolgreich erstellt!" },
      type: "Benutzertyp CFM",
    },
    group: {
      create_success: "Gruppe wurde erfolgreich erstellt!",
      update_success: "Gruppe wurde erfolgreich gespeichert!",
    },
    assign: "Region hinzufügen",
    pending: "Wartend",
    ordered: "Bestellt",
    accepted: "Angenommen",
    collected: "Abgeholt",
    weighted: "Eingang Recycling",
    confirmed: "Reycled",
    cancelled: "Storniert",
    field: { text: "Feld", empty: { error: "Das Feld muss ausgefüllt sein." } },
    edit: { text: "Bearbeiten" },
    clone: { text: "Duplizieren" },
    loading: "Lädt...",
    not_found: "Nicht gefunden!",
    cancelled_orders: "Stornierte Aufträge",
    status_unknown: "Unbekannt",
    order_sorting: "Sortierung",
    newest_order: "Neuester Auftrag",
    sortAZ: "A bis Z",
    add: { text: "Hinzufügen" },
    contact_person_name: { text: "Ansprechpartner" },
    forgot_password: { text: "Passwort vergessen?" },
    login_button: { text: "Anmelden" },
    login_title: { text: "Anmeldung GRS Portal" },
    password: { text: "Passwort" },
    select_action: { text: "Aktion auswählen" },
    select_address: { text: "Adresse auswählen" },
    select_packaging: { text: "Verpackung auswählen" },
    select_product: { text: "Produkt auswählen" },
    sign_in_to_account: { text: "Anmeldung zum Konto" },
    tare_weight_kg: { text: "Gewicht/kg" },
    services: "Leistungen",
    job: { title: "Berufsbezeichnung" },
    url: { invalid: { error: "Bitte geben Sie eine gültige Internet Adresse ein." } },
    none: "keiner",
    secondary: { recycler: "Alternativ" },
    autocomplete: { noOptions: "keine Einträge" },
    newEntry: "neuer Eintrag",
    complete: "Abschließen",
    unsavedData: "Es gibt nicht gespeicherte Änderungen. Sind Sie sicher, dass Sie die Seite verlassen wollen?",
    unsavedDataLocalPage: "Es gibt nicht gespeicherte Änderungen. Sind Sie sicher, dass Sie fortsetzen wollen?",
    warning: "Achtung!",
    leave: "Verlassen",
    toggleSidebarClose: "Menü zuklappen",
    toggleSidebarOpen: "Menü ausklappen",
    cancel_dialog: {
      title: "Vorgang abbrechen",
      message: "Möchten Sie den Vorgang wirklich abbrechen?",
      action: "Fortfahren",
    },
    actions: "Aktionen",
  },
  transferOrder: {
    updatedBy: "Ausgeführt von",
    noTransferOrders: "Es wurden keine Aufträge gefunden.",
  },
  orders: {
    recyclerOutgoingWarehouse: {
      selectWarehouse: "Wählen Sie ein Lager aus.",
      noProducts: "Es befinden sich keine Produkte in dem ausgewähltem Lager.",
      respond: "Umlagerungsauftrag",
      quantity: "{{quantity}} kg",
      notifiedQuantity: "({{quantity}} kg avisiert)",
      dialog: {
        title: "Zur Verwertung",
        submit: "Zur Verwertung",
        weightInfo: "{{productName}}: {{quantity}} kg",
        targetAddress: "Zieladresse",
        plannedDeliveryDate: "Geplantes Anlieferdatum",
      },
      filter: {
        logisticAddress: "Fraktionslager",
      },
    },
    recyclerIncomingWarehouse: {
      selectWarehouse: "Wählen Sie ein Lager aus.",
      noProducts: "Es befinden sich keine Produkte in dem ausgewähltem Lager.",
      respond: "Rückmeldung Sortierung",
      quantity: "{{quantity}} kg",
      dialog: {
        title: "Rückmeldung der Sortierung",
        submit: "Rückmelden",
        weightInfo: "{{productName}}: {{quantity}} kg",
        targetAddress: "Zieladresse",
      },
    },
    truckloadAnnounced: {
      filter: {
        logisticAddress: "Logistiklager",
        product: "Batterieprodukt",
        orderStatus: "Status",
      },
      sort: {
        plannedDeliveryDate: "Avisierungsdatum",
        truckloadNumber: "TL-Nummer",
      },
      search: {
        text: "Truckload suchen",
        toolTip: "Id, Logistikeradresse, Recycleradresse",
      },
      [TruckloadState.Draft]: "Zwischengespeichert",
      [TruckloadState.Submitted]: "Avisiert",
      [TruckloadState.Confirmed]: "Abgeschlossen",
      noData: "Es gibt keine Truckloads.",
      targetAddress: "Zieladresse: {{addressText}}",
      announcedAt: "Avisiert: {{date}}",
      edit: "Truckload bearbeiten",
      editOrRespond: "Bearbeiten/Annehmen",
      freshdeskSupport: "Support-Anfrage zu Truckload [{{truckloadId}} | {{status}}]",
    },
    truckload: {
      truckloadPerformed: {
        actualDeliveryDate: "Anlieferdatum Recycler",
        actualWeight: "Verwogenes Transportgewicht ohne Lademittel in kg",
      },
      ordersOfTruckload: {
        searchToolTip: "Id, Bestell-Nr./PO, Kundenadresse, Ansprechpartner",
      },
      cancel: {
        dialogHeader: "Truckload stornieren",
        dialogContent: "Wollen Sie die Truckload wirklich stornieren?",
      },
      addOrder: "Auftrag hinzufügen",
      addOrderEmpty: "Es wurden keine Aufträge, zum Hinzufügen, gefunden.",
      respond: "Truckload rückmelden",
      warehouse: "Lager",
      details: "Details",
      form: {
        targetAddress: "Zieladresse",
        plannedDeliveryDate: "Geplantes Anlieferdatum",
      },
      submit: "Avisieren",
      draftSave: "Zwischenspeichern",
      heading: "Truckload",
      createTruckload: "Truckload erstellen",
      chooseSelectData: "Bitte wählen Sie ein Lager und Batterieprodukt um die Truckload zusammenzustellen!",
      disabledSelectionHint: "Sie müssen zuerst ein Batterieprodukt auswählen",
      noData: "Für das ausgewählte Lager und Batterieprodukt gibt es keine Aufträge.",
      filter: {
        chooseAddress: "Wählen Sie ein Lager*",
        chooseProduct: "Wählen Sie ein Batterieprodukt*",
        choosePackage: "Verpackung",
      },
      sorting: {
        [OrderOfTruckloadSorting.OrderNumber]: "Auftragsnummer",
        [OrderOfTruckloadSorting.WarehouseEntryDate]: "Lagereingangsdatum",
      },
      selectedWeight: "Selektiertes Gewicht: <strong>{{weight}} kg</strong>",
      truckloadWeight: "Gesamtgewicht: <strong>{{weight}} kg</strong>",
      truckloadTransportWeight: "Transportgewicht (brutto): <strong>{{weight}} kg</strong>",
    },
    destinationArrived: {
      filter: {
        logisticAddress: "Lager",
      },
      selectWarehouse: "Wählen Sie ein Lager aus.",
      noOrders: "Es befinden sich keine Produkte im Lager",
      deliveryInfo: "Avisiert: {{date}}",
      weightInfo: "Transportgewicht (brutto): <strong>{{weight}} kg</strong>",
      respond: "Annehmen",
      dialog: {
        title: "Anlieferung zur Verwertung",
        weightInfo: "Transportgewicht (brutto): <strong>{{weight}} kg</strong>",
        actualDeliveryDate: "Anlieferdatum beim Verwerter",
        submit: "Annehmen",
      },
      freshdeskSupport: "Support-Anfrage zu Eingangslager Verwertung [{{transferOrderId}}]",
    },
    destinationReceivedWarehouse: {
      selectWarehouse: "Wählen Sie ein Lager aus.",
      noProducts: "Es befinden sich keine Produkte in dem ausgewähltem Lager.",
      segmentedControl: {
        recycling: "Verwertung",
        history: "Historie",
      },
      respond: "Verwerten",
      quantity: "{{quantity}} kg",
      dialog: {
        title: "Rückmeldung der Verwertung",
        submit: "Verwerten",
        weightInfo: "{{productName}}: {{quantity}} kg",
      },
      filter: {
        logisticAddress: "Verwertungslager",
        article: "Artikel",
      },
    },
    status: {
      responseDialogGenericMessage: "Bitte bestätigen Sie die Rückmeldung.",
      logistic: {
        Ordered: "Anfragen",
        LogisticAccepted: "Angenommen",
        LogisticDeclined: "Abgelehnt",
        LogisticAnnounced: "Disponiert",
        LogisticAnnouncedSingleOrder: "Logistiker Disponiert SO",
        LogisticPerformed: "Abgeholt",
        LogisticWarehouse: "Eingegangen",
        LogisticTruckLoadAnnounced: "Truckload",
        RecyclerIncomingWarehouse: "Eingangslager Truckload",
        RecyclerOutgoingWarehouse: "Fraktionslager",
        DestinationAnnounced: "Zieladresse Disponiert",
        DestinationDeclined: "Zieladresse Abgelehnt",
        DestinationArrived: "Eingangslager Verwertung",
        DestinationReceived: "Verwertung",
        Confirmed: "Abgeschlossen",
        Canceled: "Storniert",
      },
      recycler: {
        Ordered: "Anfragen",
        LogisticAccepted: "Anfragen",
        LogisticDeclined: "Abgelehnt",
        LogisticAnnounced: "Angenommen",
        LogisticAnnouncedWithPlannedDeliveryDate: "Angenommen",
        LogisticAnnouncedWithoutPlannedDeliveryDate: "Anfragen",
        LogisticAnnouncedSingleOrder: "Logistiker Disponiert SO",
        LogisticPerformed: "Abgeholt",
        LogisticWarehouse: "Eingegangen",
        LogisticTruckLoadAnnounced: "Truckload",
        RecyclerIncomingWarehouse: "Eingangslager Truckload",
        RecyclerOutgoingWarehouse: "Fraktionslager",
        DestinationAnnounced: "Zieladresse Disponiert",
        DestinationDeclined: "Zieladresse Abgelehnt",
        DestinationArrived: "Eingangslager Verwertung",
        RecyclerIncomingWarehouseWithoutTruckload: "Eingangslager Einzelauftrag",
        DestinationReceived: "Verwertung",
        Confirmed: "Abgeschlossen",
        Canceled: "Storniert",
        SorterWarehouse: "Sortierer Lager",
      },
      ShoppingCart: "Warenkorb",
      Ordered: "Bestellt",
      LogisticAccepted: "Angenommen",
      LogisticDeclined: "Abgelehnt",
      LogisticAnnounced: "Disponiert",
      LogisticAnnouncedSingleOrder: "Logistiker Disponiert SO",
      LogisticPerformed: "Abgeholt",
      LogisticWarehouse: "Eingegangen",
      LogisticTruckLoadAnnounced: "Truckload",
      RecyclerIncomingWarehouse: "Eingangslager Truckload",
      RecyclerIncomingWarehouseWithoutTruckload: "Eingangslager Einzelauftrag",
      RecyclerOutgoingWarehouse: "Fraktionslager",
      DestinationAnnounced: "Zieladresse Disponiert",
      DestinationDeclined: "Zieladresse Abgelehnt",
      DestinationArrived: "Eingangslager Verwertung",
      DestinationReceived: "Verwertung",
      Confirmed: "Abgeschlossen",
      Canceled: "Storniert",
    },
    responseButton: {
      ShoppingCart: "Warenkorb",
      Ordered: "Bestellen",
      LogisticAccepted: "Annehmen",
      LogisticDeclined: "Ablehnen",
      LogisticAnnounced: "Disponieren",
      LogisticAnnouncedSingleOrder: "Disponieren",
      LogisticPerformed: "Leistung durchgeführt",
      LogisticWarehouse: "Wareneingang",
      LogisticTruckLoadAnnounced: "LogisticTruckLoadAnnounced",
      RecyclerIncomingWarehouse: "Wareneingang melden",
      RecyclerOutgoingWarehouse: "RecyclerOutgoingWarehouse",
      DestinationAnnounced: "Disponieren",
      DestinationDeclined: "Ablehnen",
      DestinationArrived: "DestinationArrived",
      Confirmed: "Abschließen",
      Canceled: "Stornieren",
      ChangeLogistic: "Logistiker zuweisen",
      ChangePlannedPickup: "Geplantes Leistungsdatum ändern",
      LogisticMistrip: "Fehlfahrt",
    },
    responseDialog: {
      ShoppingCart: "Warenkorb",
      Ordered: "Bestellen",
      LogisticAccepted: "Annehmen",
      LogisticDeclined: "Ablehnen",
      LogisticAnnounced: "Disponieren",
      LogisticAnnouncedSingleOrder: "Disponieren",
      LogisticPerformed: "Leistung durchgeführt",
      LogisticWarehouse: "Wareneingang",
      LogisticTruckLoadAnnounced: "LogisticTruckLoadAnnounced",
      RecyclerIncomingWarehouse: "Wareneingang melden",
      RecyclerOutgoingWarehouse: "RecyclerOutgoingWarehouse",
      DestinationAnnounced: "Disponieren",
      DestinationDeclined: "Ablehnen",
      DestinationArrived: "DestinationArrived",
      Confirmed: "Abschließen",
      Canceled: "Stornieren",
      LogisticMistrip: "Fehlfahrt",
      ChangeLogistic: {
        header: "Logistiker zuweisen",
        isNotificationDisabled: "Keine Mail an Kunden, Logistiker und Recycler",
        isCustomerNotificationDisabled: "Keine Mail an Kunden",
        isRecyclerOrLogisticNotificationDisabled: "Keine Mail an Logistiker oder Recycler",
      },
      ChangePlannedPickup: {
        header: "Geplantes Leistungsdatum ändern",
        isNotificationDisabled: "Keine Mail an Kunden",
      },
      completeAction: {
        Canceled: "Stornieren und neuen Auftrag anlegen",
      },
    },
    accept_form: {
      planned_pickup_date: {
        label: "Geplantes Leistungsdatum",
      },
      request_delivery_date: { label_recyler: "Angefragtes Lieferdatum beim Recycler" },
      requested_delivery_date: {
        label: "Angefragtes Lieferdatum beim Recycler",
        recycler: { label: "Geplantes Anlieferdatum beim Recycler" },
      },
      planned_delivery_date: { label: "Geplantes Anlieferdatum beim Recycler" },
      recycler_select: { label: "Recycler" },
      actual_pickup_date: { label: "Datum Durchführung", label_recycler: "Anlieferdatum" },
      logistic_warehouse_date: { label: "Wareneingangsdatum" },
    },
    text: "Aufträge",
    overview: { text: "Auftragsübersicht" },
    repeat: {
      text: "Auftrag wiederholen",
      info: "Bitte wählen Sie den Auftrag, der erneut bestellt werden soll:",
      noData: "Es sind keine Aufträge, für das erneute Bestellen, vorhanden.",
    },
    cancelled: { text: "Stornierte Aufträge" },
    exportExcel: "Excel-Export",
    new: {
      text: "Neuer Auftrag",
      wizard: {
        completeOrder: "Auftrag abschließen",
        orderNow: "Sofort bestellen",
        addPackage: "Weitere Verpackung",
        cancelAddPackage: "keine weitere Verpackung",
        headerCreate: "Auftrag erstellen",
        headerEdit: "Auftrag bearbeiten",
        headerRepeat: "Auftrag wiederholen",
        myOrder: "Mein Auftrag",
        noData: "Keine Einträge.",
        noDataPackage: "Keine Einträge zum gewählten Produkt vorhanden.",
        noIcon: "Icon nicht verfügbar",
        submit: {
          saveEdit: "Änderungen speichern",
          saveCreate: "Zum Warenkorb hinzufügen",
          saveCreateDeactivated: "Diese Funktion ist aus technischen Gründen aktuell deaktiviert.",
          saveCreateAdmin: "Auftrag bestellen",
          soAddonQuantity: "Menge",
          toCollectAndToDeliver: "Abholung: {{collect}} Stk., Anlieferung: {{deliver}} Stk.",
          toCollect: "Abholung: {{collect}} Stk.",
          toDeliver: "Anlieferung: {{deliver}} Stk.",
          grossWeight: "Bruttogewicht",
          netWeight: "Nettogewicht",
          orderNumber: "Bestellnummer/PO",
          orderComment: "Bestellkommentar",
          requestedPickupDate: "Gewünschtes Abholdatum",
          error: {
            soAddonQuantity: "Bitte geben Sie eine Menge > 0 ein!",
            grossWeight: "Bitte geben Sie ein Bruttogewicht > 0 ein!",
            netWeight: "Bitte geben Sie ein Nettogewicht > 0 ein!",
            netWeightGreaterThenGross: "Bitte geben Sie ein Nettogewicht < Bruttogewicht ein!",
          },
        },
        package: {
          name: "Verpackung:",
          summaryContainersToCollect: "Abholung: {{containersToCollect}} {{unit}}",
          summaryContainersToDeliver: "Anlieferung: {{containersToDeliver}} {{unit}}",
          containersToBeCollected: "Abzuholende Behälter",
          containersToBeDelivered: "Anzuliefernde Leerbehälter",
          containersToBeDeliveredTooltip: "Bitte geben Sie an, wie viele leere Behälter Sie benötigen.",
          select: "Wählen Sie Ihre Verpackung:",
          selectAdditional: "Wählen Sie Ihre weitere Verpackung:",
          quantity: "Menge",
          length: "Länge",
          width: "Breite",
          height: "Höhe",
          weight: "Gewicht",
          measurementsWithQuantity:
            "{{quantity}} Stk., Länge: {{length}} mm, Breite: {{width}} mm, Höhe: {{height}} mm, Gewicht: {{weight}} kg",
          measurements: "Länge: {{length}} mm, Breite: {{width}} mm, Höhe: {{height}} mm, Gewicht: {{weight}} kg",
          error: {
            quantity: "Bitte geben Sie eine Menge > 0 ein!",
            length: "Bitte geben Sie eine Länge > 0 ein!",
            width: "Bitte geben Sie eine Breite > 0 ein!",
            height: "Bitte geben Sie eine Höhe > 0 ein!",
            weight: "Bitte geben Sie ein Gewicht > 0 kg ein!",
            containersToBeCollected:
              "Falls Sie die Lieferung von Leergut wünschen, wählen Sie bitte den entsprechenden Leergut Artikel für Ihre Bestellung aus. Für die anschließende Abholung, muss ein separater Auftrag erstellt werden.",
            containersToBeDelivered: "Bitte geben Sie eine Anzahl der anzuliefernden Behälter > 0 ein!",
          },
          hint: {
            text: "Informationen zur richtigen Verpackung entnehmen Sie bitte dem folgenden Datenblatt",
            linkText: "Datenblatt_Behälter.pdf"
          }
        },
        product: {
          select: "Wählen Sie Ihr Produkt:",
          noId: "Es wurde kein Produkt selektiert. Bitte wählen Sie ein Produkt aus.",
          hint: {
            title: "Hilfe bei der Produktauswahl",
            deviceBatteries: {
              title: "Gerätebatterien:",
              description: "Für Verpackungshinweise und Informationen zum GRS-Sicherheitsstandard besuchen Sie bitte folgende Website: ",
              link: "https://www.grs-batterien.de/sicherheit/grs-sicherheitsstandard/"
            },
            industrialBatteries: {
              title: "Industriebatterien:",
              description: "Detaillierte Informationen zu Ihrer Batterie können Sie dem Typenschild entnehmen oder über Ihren Hersteller und/oder Installateur erfragen.",
            },
            chemistryTitle: "Batteriechemie:",
            chemistry: {
              lfp: {
                title: "LFP, LMO",
                description: "Lithium-Eisen-Phosphat, Lithium-Mangandioxid"
              },
              nmc: {
                title: "NMC, NCA",
                description: "Lithium-Nickel-Mangan-Cobalt-Oxide, Lithium-Nickel-Cobalt-Aluminium-Oxide"
              },
            },
            structure: {
              title: "Bauform:",
              description: "Eine Batterie (Speicher) besteht zumeist aus mehreren Modulen, welche aus Einzelzellen hergestellt sind.",
            },
            conditionTitle: "Zustand der Batterie:",
            conditionHint: {
              title: "Hinweis:",
              description: "Die Klassifizierung unterliegt der Verantwortung des Abfallerzeugers und den Bestimmungen des ADR.",
            },
            condition: {
              securedForTransport: {
                title: "Transportsicher:",
                description: "Batterie ist unbeschädigt, sowohl physisch als auch elektronisch",
              },
              damagedSecuredForTransport: {
                title: "Beschädigt, transportsicher:",
                description: "Batterie weist einen leichten äußeren Schaden auf, zeigt aber sonst keine Reaktionen wie Wärmeentwicklung, Verformungen etc.",
              },
              crititallyDamaged: {
                title: "Kritisch, beschädigt:",
                description: "Batterie weist Anzeichen einer Beschädigung wie Wärmeentwicklung, Verformung, Austritt oder Anhaftung von Batteriebestandteilen etc. auf oder es ist davon auszugehen, dass einer dieser Zustände eintreten könnte.",
              }
            },
            ticketSubject: "Hilfe bei der Produktauswahl",
          },
        },
        wasteProducerAddress: {
          select: "Wählen Sie Ihren Leistungsort:",
          contactPersonName: "Ansprechpartner",
          contactPersonEmail: "E-Mail",
          contactPersonPhone: "Telefon",
          openingHours: "Öffnungszeiten",
          newAddress: "Neuen Leistungsort hinzufügen",
          newAddressHeading: "Leistungsort erstellen",
        },
        search: {
          group: "Gruppe suchen",
          wasteProducerAddress: "Leistungsort suchen",
          products: "Produkt suchen",
          packages: "Verpackung suchen",
        },
        group: { select: "Wählen Sie eine Gruppe:", description: "Beschreibung" },
        quantity: { text: "Menge" },
        billing_address: { header: "Rechnungsanschrift" },
        waste_producer: { header: "Abfallerzeuger" },
        products: {
          header: "Produkte",
          avv: { text: "AVV" },
          subject_number: { text: "Sach-Nr." },
          total_weight: { text: "Gesamtgewicht/kg" },
          length: { text: "Länge/mm" },
          width: { text: "Breite/mm" },
          height: { text: "Höhe/mm" },
          document_add: { text: "Beleg anfügen" },
          desired_pickup_date: { text: "Gewünschtes Abholdatum" },
          order_number_po: { text: "Bestell-Nr./PO" },
          errors: {
            no_package_for_product: "Für dieses Produkt ist keine Verpackung konfiguriert.",
          },
        },
      },
    },
    filter: { areNew: "Neue Aufträge im Status", areTotal: "Gesamtzahl Aufträge im Status" },
    recycler_form: {
      requested_delivery_date: { label: "Angefragtes Lieferdatum beim Recycler" },
      planned_pickup_date: { label: "Geplante Abholung beim Kunden" },
    },
    logistic_form: { planned_pickup_date: { label: "Geplantes Leistungsdatum Logistiker" } },
    response_values: {
      error: {
        number: "Bitte geben Sie eine Zahl > 0 ein!",
      },
      accordion: {
        title: "Rückmeldungen",
        tableheader: {
          cell: {
            feedback: "Rückmeldung",
            status: "Status",
            feedbackBy: "Rückmeldung von",
            feedbacTime: "Rückmeldezeitpunkt",
          },
        },
      },
      dialog: {
        title: "Rückmeldung",
      },
    },
    statusLabel: "Status"
  },
  order: {
    logisticMistrip: {
      cancel: "Fehlfahrt melden ohne Ersatzauftrag",
      cancelAndReorder: "Fehlfahrt melden und Ersatzauftrag erstellen",
    },
    orderId: "Auftrag {{id}}",
    weight: "Transportgewicht (brutto): <strong>{{weight}} kg</strong>",
    noData: "Keine Einträge.",
    noDataInStatus: "Es gibt keine Aufträge in diesem Status.",
    noDataInStatusWithSearchResult:
      "Es gibt keine Aufträge in diesem Status mit dem Suchbegriff <strong>{{search}}</strong>",
    details: {
      cardLabel: "Bestellung Details",
      wasteProducerAddress: "Leistungsort",
      invoiceAddress: "Rechnungsadresse",
      erp: "GRS-Kundennummer: {{erpNumber}}",
      openingHours: "Öffnungszeiten: {{openingHours}}",
      plannedPickupDate: "Geplantes Leistungsdatum Logistiker: {{date}}",
      requestedDeliveryDate: "Angefragtes Leistungsdatum beim Recycler: {{date}}",
      invoice_address: {
        title: "Rechnungsanschrift",
        group: "Gruppen",
      },
      logistic: {
        title: "Logistiker",
        planned_pickup_date: "Geplantes Leistungsdatum Logistiker",
      },
      destination: {
        title: "Zieladresse",
        destination_address: "Firmenname",
        requested_delivery_date: "Angefragtes Lieferdatum",
      },
    },
    decline: {
      warning: { message: "Hiermit lehnen Sie den Auftrag ab. Diese Aktion kann nicht rückgängig gemacht werden." },
      button: "Auftrag ablehnen",
    },
    column_name: {
      purchase_order_number: { text: "Bestell-Nr./PO" },
      order_comment: { text: "Bestellkommentar" },
      order_date: { text: "Auftragsdatum" },
      contact_person_name: { text: "Ansprechpartner" },
      contact_person_email: { text: "Ansprechpartner E-Mail" },
      contact_person_telephone: { text: "Telefonnummer" },
      status: { text: "Status" },
      recycler_address: { text: "Recycler Adresse" },
      recycler: { text: "Recycler" },
      logistic_address: { text: "Logistiker Adresse" },
      logistics_group: { text: "Logistiker Gruppe" },
      product_package: { text: "Verpackung" },
      target_gross_weight_kg: { text: "Bruttogewicht/kg" },
      custom_length: { text: "Länge/mm" },
      custom_width: { text: "Breite/mm" },
      custom_height: { text: "Höhe/mm" },
      custom_weight: { text: "Nettogewicht/kg" },
      target_net_weight_kg: { text: "Nettogewicht/kg" },
      package: { text: "Verpackung" },
      tare_weight_kg: { text: "Gewicht/kg" },
      count: { text: "Anzahl" },
      requested_pickup_date: { text: "Angefragtes Leistungsdatum" },
      planned_pickup_date: { text: "Geplantes Leistungsdatum Logistiker" },
      service_pickup_date: { text: "Leistungsdatum Logistiker" },
      is_new_for_recycle: { text: "Neu für Recycler" },
      requested_delivery_date: { text: "Angefragtes Lieferdatum beim Recycler" },
      cancelled_at: { text: "Stornodatum" },
      planned_delivery_date: { text: "Geplantes Anlieferdatum beim Recycler" },
      service_delivery_date: { text: "Anlieferdatum beim Recycler" },
      service_recycling_date: { text: "Leistungsdatum Recycling" },
      recycler_group: { text: "Abfallerzeuger Adresse" },
      address: { text: "Abfallerzeuger Adresse" },
      logistic: { text: "Logistiker" },
      timezone: { text: "Zeitzone" },
      opening_hours: { text: "Öffnungszeiten" },
      purchaseOrderNumber: "Bestell-Nr./PO",
      truckloadId: "Truckload-ID",
      routingPostal: "Gebietscode laut Routing",
      customerAddress: "Kundenadresse",
      logisticAddress: "Logistiker Adresse",
      destinationAddress: "Zieladresse",
      contactPersonName: "Ansprechpartner",
      contactPersonEmail: "Ansprechpartner E-Mail",
      contactPersonTelephone: "Telefonnummer",
      openingHours: "Öffnungszeigen",
      orderComment: "Bestellkommentar",
      servicePickupDate: "Leistungsdatum Logistiker",
      serviceDeliveryDate: "Anlieferdatum",
      serviceTreatmentDate: "Sortierdatum",
      serviceRecyclingDate: "Leistungsdatum Recycler",
      requestedPickupDate: "Angefragtes Leistungsdatum",
      plannedPickupDate: "Geplantes Leistungsdatum",
      actualPickupDate: "Tatsächliches Leistungsdatum",
      requestedDeliveryDate: "Angefragtes Anlieferdatum",
      plannedDeliveryDate: "Geplantes Anlieferdatum",
      actualDeliveryDate: "Tatsächliches Anlieferdatum",
      shoppingCartDate: "Einkaufswagen",
      orderDate: "Auftragsdatum",
      logisticAcceptedDate: "Annahme Logistiker",
      logisticAnnouncedDate: "Avisierung Logistiker",
      logisticPerformedDate: "Durchführung Logistiker",
      logisticWarehouseDate: "Lager Logistiker",
      logisticTruckLoadAnnouncedDate: "Truckload Avisierung",
      logisticTruckLoadPerformedDate: "Truckload Durchführung",
      recyclerIncomingWarehouseDate: "Eingangslager Recycler",
      recyclerOutgoingWarehouseDate: "Ausgangslager Recycler",
      destinationArrivedDate: "Zieladresse erreicht",
      confirmedDate: "Abschluss",
      canceledDate: "Stornierung",
    },
    card: {
      requested_pickup_date: "Auftragsdatum",
    },
    history: {
      changed_by: { text: "Geändert von {{user}}", adminEdit: "{{admin}} im Auftrag von {{user}}", system: "System" },
      cardLabel: "Historie",
    },
    tickets: {
      cardLabel: "Support Tickets",
      idLabel: "Ticket",
      status: {
        pending: "Ausstehend",
        open: "Offen",
        closed: "Geschlossen",
        resolved: "Gelöst",
      },
    },
    product: { add: "Verpackung hinzufügen", grossWeight: "Brutto", netWeight: "Netto" },
    status_all: { name: "Alle" },
    cfm_status_x: {
      [CfmOrderXApiStatus.ShoppingCart]: {
        name: "Warenkorb",
      },
      [CfmOrderXApiStatus.Ordered]: {
        name: "Bestellt",
      },
      [CfmOrderXApiStatus.LogisticAccepted]: {
        name: "Angenommen",
      },
      [CfmOrderXApiStatus.LogisticAnnounced]: {
        name: "Disponiert",
      },
      [CfmOrderXApiStatus.LogisticPerformed]: {
        name: "Abgeholt",
      },
      [CfmOrderXApiStatus.LogisticWarehouse]: {
        name: "Eingegangen",
      },
      [CfmOrderXApiStatus.LogisticTruckLoadAnnounced]: {
        name: "Truckload",
      },
      [CfmOrderXApiStatus.RecyclerIncomingWarehouse]: {
        name: "Eingangslager Truckload/Einzelauftrag",
      },
      [CfmOrderXApiStatus.RecyclerOutgoingWarehouse]: {
        name: "Fraktionslager",
      },
      [CfmOrderXApiStatus.DestinationArrived]: {
        name: "Eingangslager Verwertung",
      },
      [CfmOrderXApiStatus.DestinationReceived]: {
        name: "Verwertung",
      },
      [CfmOrderXApiStatus.Confirmed]: {
        name: "Abgeschlossen",
      },
      [CfmOrderXApiStatus.Canceled]: {
        name: "Storniert",
      },
    },
    print_delivery_note: { button: "Lieferschein drucken" },
    resetSearchResult: "Suchkriterien zurücksetzen",
    response: {
      cardLabel: "Rückmeldungen",
    },
    bulk_update: {
      tooltip: "Staffelbearbeitung leider nicht möglich, weil die Produkte unterschiedlich konfiguriert sind.",
      action_default: "Aktion wählen",
    },
    totalWeightInWarehouse: "Gesamtgewicht auf Lager: <strong>{{weight}} kg</strong>",
  },
  recycler: { overview: { title: "Recyclerübersicht" } },
  logistics: {
    text: "Logistik",
    overview: { title: "Logistikerübersicht" },
    logistic: { name: { text: "Logistiker" } },
    recycler: { name: { text: "Recycler" } },
    address: { text: "Standort Adresse" },
    select_logistic_and_product: "Produkt und Logistiker auswählen",
    assigned_regions: "Zugeteilte Regionen",
    postal: "Region/PLZ",
    remove_assignments: "Zuweisung(en) entfernen",
    role_in_region: "Rolle in PLZ",
    assigned_at: "Zugeteilt am",
    assigned_from: "Zugeteilt von",
    logistic_description: "Logistiker Beschreibung",
    recycler_description: "Recycler Beschreibung",
    override_assignment: { question: "Möchten Sie folgende Zuweisungen überschreiben?" },
    override_assignments_exist: "Folgende Zuweisungen bestehen bereits:",
    assign_select_type_state: { error: "Bitte wählen Sie Logistiker, Adresse und Produkt aus." },
    assign_select_only_single: {
      error:
        "Um die Zuweisung zu speichern, muss genau eine Gruppe, genau eine Adresse und genau ein Produkt ausgewählt sein.",
    },
    logistics: { filter: { nofilter: "kein Filter" } },
    override_assignments_exists: "Folgende Zuweisungen bestehen bereits:",
  },
  assignments: {
    error: {
      already_assigned: "Dieser Eintrag ist bereits zugewiesen.",
      not_valid: "Die Region muss eine positive Zahl oder 'DE' sein.",
      map_data_not_loaded: "Fehler beim Laden der Kartendaten!",
    },
  },
  table: {
    deleteTooltip: "Eintrag entfernen",
    editTooltip: "Eintrag bearbeiten",
    nRowsSelected: "{{0}} Zeile(n) ausgewählt",
    editRow: {
      cancelTooltip: "Abbrechen",
      saveTooltip: "Speichern",
      deleteText: "Möchtest du diesen Eintrag entfernen?",
    },
    toolbar: { searchTooltip: "Suche", searchPlaceholder: "Suche..." },
    body: { emptyDataSourceMessage: "Keine Daten vorhanden" },
    labelDisplayedRows: "{{from}}-{{to}} von {{count}}",
    labelDisplayedRowsCustomTable: "{from}-{to} von {count}", // custom table parses differently
    labelRowsSelect: "Einträge",
    labelRowsPerPage: "Einträge pro Seite:",
    firstTooltip: "Erste Seite",
    previousTooltip: "Vorherige Seite",
    nextTooltip: "Nächste Seite",
    lastTooltip: "Letzte Seite",
  },
  pdf: {
    package_content: {
      ordernumber: "Bestellnr.",
      orderitem: "Bestellposition",
      product: "Produkt",
      fraction: "Fraktion",
      status: "Zustand",
      type: "Type",
      package: "Verpackung",
      qr_subtext: "Bestellung in GRS Portal Platform öffnen",
    },
    page_title: {
      header1: "Abhol-/Lieferschein",
      header2: "Kennzeichnung für Verpackungseinheit {{currentIndex}} von {{pageCount}}",
    },
    target_location_recycler: { destination: "Zielort" },
    warning: { register_document: "Bitte aufbewahren, dient als abfallrechtlicher Registerbeleg" },
    delivery_note: {
      signs: {
        order_placed: "Auftrag erteilt",
        date: "Datum",
        pick_up_confirmation: "Abholbestätigung",
        dismantling: "Demontage",
        recycling: "Recycling",
        delivery_confirmation: "Anlieferbestätigung",
        customer_signature: "Auftraggeber Unterschrift",
        logistic_signature: "Logistik Unterschrift",
        dismantling_signature: "Demontage Unterschrift",
        recycler_signature: "Recycler Unterschrift",
        generic_signature: "Unterschrift",
      },
      order_information: {
        company_order_nr: "GRS Service GmbH Auftrags-Nr.:",
        further_inquiry: "Bei Rückfragen bitte anführen",
        delivery_address: "Lieferanschrift:",
        no_recycler: "kein Recycler",
        routing_info: "GRS Routing Information:",
        logistic: "Logistiker:",
        pick_up_date: "Abholdatum:",
        recycler: "Recycler:",
        planned_delivery_date: "Geplantes Anlieferdatum:",
        waste_producer: "Abfallerzeuger:",
        collection_address: "Abholanschrift:",
        contact_person_local: "Ansprechpartner vor Ort:",
        contact_person_order: "Ansprechpartner der Bestellung:",
      },
      table: {
        battery_collection: "Batterie-Abholung",
        avv: "AVV",
        part_nr: "Sach-Nr.",
        unit: "ME",
        customer: "Auftraggeber",
        total_weight: "Gesamtgewicht",
        gross: "Brutto",
        order_amount: "Auftragsmenge",
        one: "(1)",
        logistic: "Logistik",
        gross_weight: "Bruttogewicht",
        two: "(2)",
        recycler: "Recycler",
        net_weight: "Nettogewicht",
        recycling: "Recycling",
        receiver: "Empfänger",
        quantity: "Menge",
        three: "(3)",
        additional_services: "Zusatzleistungen",
        action: "Aktion",
      },
    },
  },
  navigation: {
    configuration: {
      configurator: "Konfigurator",
      overview: "Übersicht",
      createProductConfig: "Artikel",
      createPackageConfig: "Verpackung",
      routing: "Routings",
      responses: "Rückmeldungen",
      responseFields: "Rückmeldefelder",
      pom: "POM-Konfiguration",
    },
    basedata: {
      label: "Stammdaten",
      response_field_config: {
        label: "Rückmeldekonfiguration",
        description: "Konfigurieren die Felder einzelnen Masken im Portal",
        response_field: { label: "Rückmeldungsfeld" },
      },
      users: { label: "Benutzerübersicht" },
      addresses: { label: "Adressübersicht" },
      groups: {
        label: "Gruppenübersicht",
        account: "Meine Kontoverwaltung",
      },
    },
    orders: {
      cancelled: { label: "Stornierte Aufträge", description: "Die vom User stornierten Aufträge" },
      label: "Aufträge",
      new: { label: "Auftrag erstellen" },
      overview: { label: "Auftragsübersicht" },
      repeatOverview: { description: "Auftrag wiederholen", label: "Auftrag wiederholen" },
    },
    cart: {
      label: "Warenkorb",
    },
    logistics: {
      label: "Logistik",
    },
    recycling: { label: "Recycling" },
    pom: {
      batt_g: { register: { text: "BattG-Registrierung", master_data: "BattG-Stammdaten", brands: "BattG-Marken" } },
    },
    config: {
      label: "Konfigurator",
      overview: {
        label: "Übersicht",
      },
      products: { label: "Artikel" },
      packages: { label: "Verpackungen" },
      routing: { label: "Routings" },
      responses: { label: "Rückmeldungen" },
    },
    setting: { content: { user: "Benutzer erstellen" } },
  },
  configuration: {
    overview: {
      heading: "Übersicht",
      noData: "Es gibt keine Artikel mit den gesetzten Suchkriterien und Suchbegriffen.",
      reset: "Suchkriterien zurücksetzen",
      filter: {
        [ProductOverviewGeneralFilter.All]: "Alle",
        [ProductOverviewGeneralFilter.WithoutPackages]: {
          default: "Ohne Verpackung",
          packageOverview: "Nicht zugewiesene",
        },
        [ProductOverviewGeneralFilter.WithoutRouting]: {
          default: "Ohne Routing",
          routingOverview: "Artikel ohne Routing",
        },
        [ProductOverviewGeneralFilter.WithoutResponseAssignments]: {
          default: "Ohne Rückmeldung",
          responseOverview: "Ohne Zuweisung",
        },
        [ProductOverviewGeneralFilter.Inactive]: {
          default: "Inaktiv",
        },
      },
    },
    currentPackage: "Aktuelle Verpackung",
    currentRouting: "Aktuelles Routing",
    currentResponseAssignment: "Aktuelle Rückmeldungen",
    article: "Artikel",
    packages: "Verpackungen",
    choosePackages: "Verpackungen auswählen",
    finishPackageConfiguration: "Verpackungskonfiguration abschließen",
    newProduct: "Neuer Artikel",
    newRouting: "Neues Routing",
    newResponseField: "Rückmeldefeld hinzufügen",
    createNewRouting: "Neues Routing erstellen",
    editRouting: "Routing bearbeiten",
    createNewResponse: "Rückmeldung konfigurieren",
    configArticle: "Artikel konfigurieren",
    configPackage: "Verpackungen konfigurieren",
    configRouting: "Routing konfigurieren",
    configResponse: "Rückmeldungen konfigurieren",
    platforms: "Geschäftsbereich",
    articleTypes: "Artikelart",
    categories: "Vereinbarung",
    fractions: "Fraktion",
    types: "Artikeltyp",
    states: "Transportstatus",
    orderStatus: "Bestellstatus",
    product: "Produktartikel",
    singleOrderProduct: "Bestellartikel",
    createProducts: "Artikel erstellen",
    alreadyExisting: "Bereits vorhanden",
    resetConfig: "Auswahl zurücksetzen",
    successfullyCreated: "Artikel erfolgreich konfiguriert!",
    redirectToPackageConfig: "Möchten Sie nun die Verpackungen konfigurieren?",
    productCount: "{{count}} Produkt(e)",
    search: {
      products: "Artikel suchen",
      productsToolTip: "Artikelnummer, Beschreibung, Datum",
      responseFields: "Rückmeldungsfeld suchen",
      responseFieldToolTip: "Id, Name, Beschreibung, Einheit, Interne Nummer",
    },
    sortBy: "Sortierung nach",
    createDate: "Änderungs-/Erstelldatum",
    articleNumber: "Artikelnummer",
    region: "Region",
    updateProductName: "Bezeichnung ändern",
    toPackageConfig: "Zur Verpackungsauswahl",
    toCreateRoutingConfig: "Zur Routingauswahl",
    toResponseConfig: "Status zuweisen",
    noPackages: "Keine Verpackungen.",
    removePackageConfig: {
      header: "Verpackungszuweisung entfernen",
      text: "Wollen Sie die zugewiesene Verpackung ({{pkgName}}) entfernen?",
    },
    createProductPackageConfig: {
      showPackageConfigs: "Zeige Verpackungskonfiguration",
      submit: "Abschließen",
    },
    productPackageConfig: {
      success: "Verpackung erfolgreich konfiguriert!",
      toRoutingQuestion: "Möchten Sie nun das Routing konfigurieren?",
    },
    productBadge: {
      active: "aktiv",
      inactive: "inaktiv",
    },
    postalOrRegion: "PLZ/Region",
    addresses: "Logistiker",
    assignments: {
      main: "Hauptlogistiker",
      secondary: "Alternativlogistiker",
      level: {
        primary: "Primäre",
        secondary: "Sekundäre",
      },
      dialog: {
        title: "Routing löschen",
        content: "Wollen Sie das Routing löschen?",
      },
      startAddress: "{{level}} Startadresse",
      targetAddress: "{{level}} Zieladresse",
      primaryStartAddress: "Primäre Startadresse",
      primaryTargetAddress: "Primäre Zieladresse",
      secondaryStartAddress: "Sekundäre Startadresse",
      secondaryTargetAddress: "Sekundäre Zieladresse",
      optionalAddresses: "Optionale Zieladressen",
      create: {
        assignRouting: "Routing zuweisen",
        showRoutingConfig: "Zeige Routingkonfiguration",
        submit: "Abschließen",
        postalHeading: "Postleitzahl zuweisen",
        uploadPostal: "Postleitzahlen hochladen",
        downloadTemplate: "Excel-Vorlage herunterladen",
        confirmPostals: "Übernehmen",
        extractedPostals: "Importierte Postleitzahlen:",
        fallbackPostal: "Deutschland gesamt",
        success: "Routing erfolgreich erstellt!",
        toOverview: "Zur Übersicht",
        addressHeading: "Start-/Zieladresse zuweisen",
        startAddress: "Startadresse Logistiker",
        targetAddress: "Zieladresse",
        additionalEndAddresses: "Weitere Zieladressen",
        newAddress: "Weitere Zieladressen",
      },
    },
    responseAssignments: {
      billingRelevant: "Faktura relevant",
      credit: "Gutschrift",
      warehouseArticle: "Lagerartikel",
      warehouseArticleAlreadyAssigned: "Es ist bereits ein Lagerartikel zugewiesen",
      create: {
        orderStatusHeading: "Status zuweisen",
        responseFieldsHeading: "Rückmeldefelder konfigurieren",
        responseFields: "Rückmeldefelder",
        copyConfig: "Bestehende Konfiguration zuweisen",
        useConfig: "Rückmeldekonfiguration übernehmen",
        chooseConfig: "Wählen Sie den Artikel, dessen RückmeldeKonfiguration Sie übernehmen möchten",
        noResponseFields: "Keine Rückmeldefelder ausgewählt.",
        success: "Rückmeldekonfiguration erfolgreich erstellt!",
        error: {
          billableResponseFieldNumberOnly:
            "Es dürfen nur Zahlen bei verrechenbaren Rückmeldefeldern konfiguriert werden!",
        },
        submit: "Konfiguration abschließen",
      },
      configResponseFields: "Rückmeldefelder konfigurieren",
      dates: {
        plural: "Service Dates",
        [CfmServiceDate.Delivery]: "Service Delivery Date",
        [CfmServiceDate.Pickup]: "Service Pickup Date",
        [CfmServiceDate.Recycling]: "Service Recycling Date",
        [CfmServiceDate.Treatment]: "Service Treatment Date",
      },
    },
    responseField: {
      noUnit: "keine Einheit",
      inUse: "Deaktivieren nicht möglich, da es noch aktive Zuweisungen gibt!",
      heading: "Rückmeldekonfiguration",
      filter: {
        type: "Typ",
        status: "Status",
      },
      type: {
        text: "Text",
        number: "Ganzzahl",
        floating_point: "Fließkomma",
        text_area: "Textbereich",
        date: "Datum",
        file: "Datei",
      },
      status: {
        Active: "Aktiv",
        Inactive: "Inaktiv",
      },
      rowHeader: {
        id: "ID",
        internalNumber: "Interne Nummer",
        type: "Typ",
        name: "Name",
        description: "Beschreibung",
        unit: "Einheit",
        active: "Status",
      },
      badge: {
        active: "aktiv",
        inactive: "inaktiv",
      },
      dialog: {
        updateTitle: "Rückmeldefeld bearbeiten",
        createTitle: "Neues Rückmeldefeld",
        form: {
          type: "Typ",
          name: "Name",
          description: "Beschreibung",
          unit: "Einheit",
          internalNumber: "Interne Nummer",
          active: "aktiv",
        },
      },
    },
    pom: {
      heading: {
        title: "POM-Konfiguration",
        announcement_reduction_period: "Zeitraum für Reduzierung von Meldungen",
        announcement_grace_period: "Meldefrist nach Vertragsende",
        announcement_reminder_due_date: "Fälligkeitstag der Meldungserinnerung"
      },
      form: {
        announcement_grace_period: "Meldefrist nach Vertragsende (Tage)",
        announcement_reduction_allowed_period_start: "Von",
        announcement_reduction_allowed_period_end: "Bis",
      },
      error: {
        update: "POM-Konfiguration konnte nicht aktualisiert werden!",
        announcement_grace_period: "Bitte geben Sie eine Zahl >= 0 ein!",
      }
    }
  },
  basedata: {
    address: {
      edit_forbidden: "Die Adresse darf nicht bearbeitet werden!",
      prefillAddress: "Gruppenadresse übernehmen",
    },
    users: {
      heading: "Benutzer",
      table: {
        userId: "ID",
        type: "Typ",
        role: "Rolle",
        role_cfm: "Rolle CFM",
        role_pom: "Rolle POM",
        last_login: "Letzte Anmeldung",
        erp_number: "ERP-Nummer",
        groupName: "Firma",
        name: "Name",
        email: "E-Mail",
        group: {
          admin: "Admin",
          customer: "Kunde",
          cfm_customer: "CFM Kunde",
          cfm_logistics: "CFM Logistiker",
          cfm_recycler: "CFM Recycler",
          pom: "POM",
          pom_and_cfm_customer: "POM, CFM Kunde",
          pom_agency: "POM Agentur",
          pom_representative: "POM Bevollmächtigter",
        },
        filter: { status: { text: "Status", active: "Aktiv", inactive: "Inaktiv" }, group: "Gruppe" },
        action: {
          deactivate: "Eintrag deaktivieren",
          activate: "Eintrag aktivieren",
          edit: "Eintrag bearbeiten",
          detail: "Detailansicht öffnen",
          login: "Anmelden als Benutzer",
          loginNotAvailable: '"Anmelden als" nicht möglich; Benutzer ist deaktiviert oder selbst Admin!',
          logout: "Anmeldung als Super Admin",
          add: "Neuer Eintrag",
        },
        search: "ID, ERP Nummer, Firma, Name, E-Mail, Telefonnummer",
      },
      login: {
        text: "Möchten Sie sich als Benutzer {{firstName}} {{lastName}} anmelden?",
        button: "login als {{firstName}} {{lastName}}",
        header: "Als Benutzer anmelden",
        successLogin: "Erfolgreich als Benutzer angemeldet",
        successLogout: "Erfolgreich als Benutzer abgemeldet",
      },
      logout: {
        header: "Als Benutzer abmelden",
        text: "Möchten Sie sich als Admin anmelden?",
        tooltip: "Abmelden als {{fullName}}",
        agency_text: "Möchten Sie sich als Agentur anmelden?",
        agency_action: "Anmeldung als Agentur",
      },
      edit: {
        text: "Benutzer bearbeiten",
        groupName: "Gruppe",
        title: "Anrede",
        jobtitle: "Berufsbezeichnung",
        id: "ID",
        firstName: "Vorname",
        lastName: "Nachname",
        language: "Sprache",
        active: "Aktiv",
        externalId: "Externe ID",
        lastLogin: "Letzte Anmeldung",
        pom_type: "Benutzertyp POM",
        cfm_type: "Benutzertyp CFM",
        all_addresses: "Benutzer immer für alle Abfallerzeuger freischalten",
        loginEmail: "E-Mail (login)",
        deliveryEmail: "E-Mail (Benachrichtigungen)",
        password: {
          text: "Passwort",
          reenter: "Passwort wiederholen",
          error:
            "Die Passwörter müssen übereinstimmen, min. 10 Zeichen haben, Klein- und Großbuchstaben, min. eine Zahl und min. ein Sonderzeichen beinhalten.",
        },
        phone: "Telefonnummer",
        role: "Rolle",
        locations: "Adressen",
        option: { all: "ALLE" },
        pom_role: { announcer: "POM Melder", main_contact: "POM Hauptansprechpartner" },
      },
      create: "Benutzer erstellen",
      detail: "Benutzer-Details",
      title: { male: "Herr", female: "Frau", diverse: "Divers" },
    },
    addresses: {
      groupDetailAddress: {
        header: "Leistungsort",
        details: "Leistungsort Details",
        edit: "Leistungsort bearbeiten",
      },
      cannotDisable: "Adresse kann nicht deaktiviert werden.",
      edit: {
        id: "ID",
        text: "Adresse bearbeiten",
        addressType: {
          type: "Typ",
          disabled: { tooltip: "Änderung nur möglich, wenn die Zuweisung zur Gruppe gelöscht wird." },
        },
        name: "Name",
        name2: "Name 2",
        state: "Bundesland",
        address: "Adresse",
        address2: "Adresse 2",
        buildingNumber: "Hausnummer",
        postal: "PLZ",
        city: "Ort",
        contactPersonPhone: "Ansprechpartner - Telefon",
        contactPersonName: "Ansprechpartner - Name",
        contactPersonEmail: "Ansprechpartner - E-Mail",
        lng: "Längengrad",
        lat: "Breitengrad",
        addressText: "Adresstext",
        country: "Land",
        opening_hours: "Öffnungszeiten",
      },
      create: "Adresse erstellen",
      detail: "Adresse Details",
      type: {
        invoice: "Rechnungsadresse",
        customer_location: "Kundenadresse",
        group_location: "Gruppenadresse",
        logistic_location: "Logistikeradresse",
        recycling_location: "Recycleradresse",
        sorter_location: "Sortiereradresse",
        manufacturer_location: "Herstelleradresse",
      },
      routingType: {
        invoice: "Rechnung",
        customer_location: "Kunde",
        group_location: "Gruppe",
        logistic_location: "Logistiker",
        recycling_location: "Recycler",
        sorter_location: "Sortierer",
        manufacturer_location: "Hersteller",
      },
      heading: "Adressen",
      table: {
        addressId: "ID",
        addressType: { type: "Typ", cantResolve: "Addresstyp konnte nicht übersetzt werden" },
        name: "Name",
        addressAndBuilding: "Adresse und Hausnummer",
        postal: "PLZ",
        city: "Ort",
        country: "Land",
        group: "Gruppe",
        noEntry: "kein Eintrag",
        search: "ID, Name, Adresse und Hausnummer, PLZ, Ort, Gruppe",
      },
      filters: { type: "Typ" },
    },
    groups: {
      groupContracts: {
        [GroupContractFormMode.Edit]: "Vereinbarung bearbeiten",
        [GroupContractFormMode.Detail]: "Vereinbarung Details",
        [GroupContractFormMode.Create]: "Vereinbarung erstellen",
        contractNumber: "Vereinbarungsnummer",
        startDate: "Startdatum",
        endDate: "Enddatum",
        productCategory: "Produktkategorie",
        contractType: "Vereinbarungstyp",
        takeBackSystem: "Rücknahmesystem",
        refreshContractNumber: "Nächste verfügbare Vereinbarungsnummer laden",
        contractNumberNotAvailable: "Die Vereinbarungsnummer ist bereits vergeben",
        pomContractAlreadyExists: "Es existiert bereits eine POM-Vereinbarung für Ihre Gruppe!",
        cfmContractAlreadyExists: "Es existiert bereits eine CFM-Vereinbarung für Ihre Gruppe!",
      },
      contractsTable: {
        type: "Typ",
        cfmType: "CFM",
        pomType: "POM",
        system: "System",
        contract_number: "Vereinbarungsnummer",
        dates: "Gültig von - bis",
        add_new: "Vereinbarung hinzufügen",
        contracts: "Vereinbarungen",
        [GroupContractType.Cfm]: "CFM",
        [GroupContractType.Pom]: "POM",
        not_default_price_group: "keine Standardpreisgruppe",
        default_price_group: "Standardpreisgruppe",
      },
      edit: {
        isPomAgencyAnnouncer: "i.S.d. BattG",
        text: "Stammdaten bearbeiten",
        groupType: "Typ",
        groupTypeHeading: "Gruppentyp bearbeiten",
        name: "Gruppenname",
        contractNumberCFM: "Vertragsnummer CFM",
        contractNumberPOM: "Vertragsnummer POM",
        start_date: "Startdatum",
        end_date: "Enddatum",
        PomContractStartDate: "Vertrag POM Start",
        PomContractEndDate: "Vertrag POM Ende",
        EARTemporaryNumber: "ear Vorgangsnummer",
        EARNumber: "ear Registrierungsnummer DE",
        externalId: "Externe ID",
        description: "Beschreibung",
        erpNumber: "ERP Nummer",
        address: "Adresse",
        invoiceAddress: "Rechnungsadresse",
        groupAddress: "Gruppenadresse",
        addressesOfType: "Addressen {{types}}",
        productCategories: "Produktkategorien",
        defaultPriceGroup: "Standardpreisgruppe",
        grsManagedEar: "EAR-Verwaltung durch GRS",
        tradeRegisterNumber: "Handelsregister-Nummer",
        webAddress: "Internet-Adresse",
        vatId: "Umsatzsteuer-ID",
        agencyGroup: "Agentur",
        UsedBy: "Verwendet von: ",
        partialEditError: "{{name}} muss vollständig angegeben werden.",
        groupBranch: "Branche",
      },
      existingDebitor: {
        heading: "Datenüberprüfung",
        existingInfo: "{{fields}} bereits vorhanden. Möchten Sie die Gruppe dennoch anlegen?",
        fields: {
          isExistingName: "Gruppenname",
          isExistingVatId: "Umsatzsteuer-ID",
          isExistingTradeRegisterNumber: "Handelsregister-Nummer",
        },
      },
      create: "Gruppe erstellen",
      detail: {
        text: "Gruppe Details, ID - {{id}}",
        addresses: "Adressen",
        NoAddresses: "keine Adressen",
        NoAddress: "keine Adresse",
        searchWasteProducerAddress: {
          text: "Rücknahmestelle suchen",
          tooltip: "Firmenname, Adresse und Hausnummer, PLZ, Ort, Land",
        },
        searchUsers: "Benutzer suchen",
      },
      heading: "Gruppen",
      table: {
        groupId: "ID",
        name: "Name",
        description: "Beschreibung",
        type: "Typ",
        cantDelete: "Gruppe kann nicht deaktiviert werden, da es noch aktive Benutzer gibt.",
        search: {
          cfm: "ID, Name, ERP Nummer, Adresse, Vertragsnummer CFM, Vertragsnummer POM",
          pom: "ID, Name, Agentur/Bevollmächtigter, ERP Nummer, Adresse",
          agency: "ID, Name",
        },
      },
      take_back_system: "Rücknahmesystem",
      refresh_erp_number: "Nächste verfügbare ERP-Nummer laden",
      edit_erp_number: "ERP-Nummer ändern",
      pom_announcement_period: "Meldungszeitraum",
      historyTable: {
        search: "Alter Wert, Neuer Wert",
        searchPlacehoder: "Änderung suchen",
        description: "Sektionsbezeichnung, ID, Feldname, alter Wert, neuer Wert, Zeitstempel, bearbeitet von",
        infoType: {
          label: "Sektionsbezeichnung",
          [GroupHistoryInformationType.AddressInfo]: "Standort",
          [GroupHistoryInformationType.GroupInfo]: "Gruppe Details",
          [GroupHistoryInformationType.UserInfo]: "Benutzer",
          [GroupHistoryInformationType.PaymentInfo]: "Bankdaten",
          [GroupHistoryInformationType.GroupGroupTypeInfo]: "Gruppentyp",
          [GroupHistoryInformationType.GroupContractInfo]: "Vereinbarung",
        },
        groupGroupType: {
          fields: {
            [GroupHistoryGroupGroupTypeInfoColumn.GroupId]: "Gruppen-ID",
            [GroupHistoryGroupGroupTypeInfoColumn.TypeId]: "Typ",
          },
        },
        paymentInfo: {
          fields: {
            [GroupHistoryPaymentInfoColumn.PaymentInfoId]: "ID",
            [GroupHistoryPaymentInfoColumn.BicOrSwift]: "BIC/SWIFT",
            [GroupHistoryPaymentInfoColumn.Iban]: "IBAN",
            [GroupHistoryPaymentInfoColumn.Name]: "Name",
          },
        },
        user: {
          fields: {
            [GroupHistoryUserInfoColumn.Id]: "ID",
            [GroupHistoryUserInfoColumn.Uuid]: "UUID",
            [GroupHistoryUserInfoColumn.GroupId]: "Gruppen ID",
            [GroupHistoryUserInfoColumn.Title]: "Titel",
            [GroupHistoryUserInfoColumn.FirstName]: "Vorname",
            [GroupHistoryUserInfoColumn.LastName]: "Nachname",
            [GroupHistoryUserInfoColumn.DeliveryMail]: "E-Mail (Benachrichtigungen)",
            [GroupHistoryUserInfoColumn.Email]: "E-Mail (login)",
            [GroupHistoryUserInfoColumn.Telephone]: "Telefon",
            [GroupHistoryUserInfoColumn.Language]: "Sprache",
            [GroupHistoryUserInfoColumn.JobTitle]: "Berufsbezeichnung",
            [GroupHistoryUserInfoColumn.UserTypeLevel]: "Benutzertyp",
            [GroupHistoryUserInfoColumn.MustResetPassword]: "muss Passwort zurücksetzen",
            [GroupHistoryUserInfoColumn.Locked]: "gesperrt",
            [GroupHistoryUserInfoColumn.RolePom]: "Rolle Pom",
            [GroupHistoryUserInfoColumn.ExternalId]: "Externe ID",
            [GroupHistoryUserInfoColumn.Active]: "Aktiv",
          },
        },
        group: {
          fields: {
            [GroupHistoryGroupInfoColumn.Id]: "ID",
            [GroupHistoryGroupInfoColumn.Uuid]: "UUID",
            [GroupHistoryGroupInfoColumn.Name]: "Gruppenname",
            [GroupHistoryGroupInfoColumn.Name2]: "Gruppenname2",
            [GroupHistoryGroupInfoColumn.Description]: "Beschreibung",
            [GroupHistoryGroupInfoColumn.ErpNumber]: "ERP Nummer",
            [GroupHistoryGroupInfoColumn.Active]: "Aktiv",
            [GroupHistoryGroupInfoColumn.ExternalId]: "Externe ID",
            [GroupHistoryGroupInfoColumn.ContractStartDate]: "Vertrag POM Start",
            [GroupHistoryGroupInfoColumn.ContractEndDate]: "Vertrag POM Ende",
            [GroupHistoryGroupInfoColumn.ContractNumberCfm]: "Vertragnummer CFM",
            [GroupHistoryGroupInfoColumn.ContractNumberPom]: "Vertragnummer POM",
            [GroupHistoryGroupInfoColumn.PomAnnouncementPeriod]: "Meldeperiode",
            [GroupHistoryGroupInfoColumn.EarNumber]: "ear Registrierungsnummer DE",
            [GroupHistoryGroupInfoColumn.EarTemporaryNumber]: "ear Vorgangsnummer",
            [GroupHistoryGroupInfoColumn.VatId]: "Umsatzsteuer-ID",
            [GroupHistoryGroupInfoColumn.DefaultPriceGroup]: "Standardpreisgruppe",
            [GroupHistoryGroupInfoColumn.WebAddress]: "Internet-Adresse",
            [GroupHistoryGroupInfoColumn.TradeRegisterNumber]: "Handelsregister-Nummer",
            [GroupHistoryGroupInfoColumn.GrsManagedEar]: "EAR-Verwaltung durch GRS",
            [GroupHistoryGroupInfoColumn.HasAcceptedSepaMandate]: "SEPA-Lastschrift aktiv",
            [GroupHistoryGroupInfoColumn.GroupBranchId]: "Branch",
          },
        },
        address: {
          fields: {
            [GroupHistoryAddressInfoColumn.Id]: "ID",
            [GroupHistoryAddressInfoColumn.Uuid]: "UUID",
            [GroupHistoryAddressInfoColumn.Type]: "Typ",
            [GroupHistoryAddressInfoColumn.Name]: "Name",
            [GroupHistoryAddressInfoColumn.Name2]: "Abteilung",
            [GroupHistoryAddressInfoColumn.Address]: "Adresse",
            [GroupHistoryAddressInfoColumn.AddressText]: "Addresstext",
            [GroupHistoryAddressInfoColumn.BuildingNumber]: "Hausnummer",
            [GroupHistoryAddressInfoColumn.City]: "Ort",
            [GroupHistoryAddressInfoColumn.Postal]: "PLZ",
            [GroupHistoryAddressInfoColumn.State]: "Bundesland",
            [GroupHistoryAddressInfoColumn.Country]: "Land",
            [GroupHistoryAddressInfoColumn.Lat]: "Längengrad",
            [GroupHistoryAddressInfoColumn.Long]: "Breitengrad",
            [GroupHistoryAddressInfoColumn.ContactPersonEmail]: "Ansprechpartner - E-Mail",
            [GroupHistoryAddressInfoColumn.ContactPersonName]: "Ansprechpartner - Name",
            [GroupHistoryAddressInfoColumn.ContactPersonTelephone]: "Telefonnummer",
            [GroupHistoryAddressInfoColumn.ExternalId]: "Externe ID",
            [GroupHistoryAddressInfoColumn.Active]: "Aktiv",
          },
        },
        contract: {
          fields: {
            [GroupHistoryGroupContractInfoColumn.Id]: "ID",
            [GroupHistoryGroupContractInfoColumn.ContractId]: "ID",
            [GroupHistoryGroupContractInfoColumn.ContractNumber]: "Vereinbarungsnummer",
            [GroupHistoryGroupContractInfoColumn.ContractField]: "System",
            [GroupHistoryGroupContractInfoColumn.Type]: "Typ",
            [GroupHistoryGroupContractInfoColumn.StartDate]: "Gültig von",
            [GroupHistoryGroupContractInfoColumn.EndDate]: "Gültig bis",
            [GroupHistoryGroupContractInfoColumn.DefaultPriceGroup]: "Standardpreisgruppe",
            [GroupHistoryGroupContractInfoColumn.PomAnnouncementPeriod]: "Meldeperiode",
          },
        },
        id: "ID",
        columnName: "Feldname",
        oldValue: "alter Wert",
        newValue: "neuer Wert",
        updatedBy: "bearbeitet von",
        timeStamp: "Zeitstempel",
        noUpdatedByInfo: "Keine Information vorhanden",
        heading: "Änderungen Stammdaten",
      },
    },
    differentInvoice: "Abweichende Rechnungsadresse",
    response_fields: { table: { search: "ID, Interne Nummer, Name, Beschreibung, Einheit" } },
    response_assignments: { table: { search: "ID, Name, Beschreibung" } },
    general: {
      yes: {
        text: "Ja",
      },
      no: {
        text: "Nein",
      },
      user: { update_success: "Benutzer wurde erfolgreich geändert!" },
      address: {
        update_success: "Adresse wurde erfolgreich geändert!",
        create_success: "Adresse wurde erfolgreich erstellt!",
        create_error: "Beim Erstellen der Adresse ist ein Fehler aufgetreten!",
      },
    },
    group: {
      groupType: "Gruppentyp",
      error: {
        get_next_erp_number:
          "Die nächste ERP-Nummer konnte aufgrund eines unerwarteten Fehlers nicht abgerufen werden!",
        is_erp_number_available:
          "Die Verfügbarkeit dieser ERP-Nummer konnte aufgrund eines unerwarteten Fehlers nicht überprüft werden!",
        erp_number_unavailable: "Die angegebene ERP-Nummer wurde zwischenzeitlich bereits vergeben!",
      },
      agency_or_manufacturer_title: "Agentur/Bevollmächtigter",
      payment_info: { bic_or_swift: "BIC/SWIFT" },
      pom_heading: "POM",
      cfm_heading: "CFM",
      address_heading: "Gruppenadresse / Rechnungsadresse",
    },
    manufacturer: {
      name: "Name",
      name2: "Name2",
      address: "Adresse",
      battreg_number: "Battreg Nummer",
      contract_starts_at: "Vertragsbeginn",
      contract_ends_at: "Vertragsende",
      group: "Gruppe",
      table: {
        search: "Name, Adresse und Hausnummer, PLZ, Ort, Land, Vertragsbeginn, Vertragsende",
        searchPlaceholder: "Hersteller suchen",
      },
      heading: "Hersteller",
    },
    payment_info: {
      heading: "Bankverbindung",
      name: "Kontoinhaber",
      iban: "IBAN",
      active: "SEPA-Lastschrift aktiv",
      inactive: "SEPA-Lastschrift nicht aktiv",
      noData: "Keine Bankverbindung hinterlegt.",
    },
  },
  country: {
    ad: "Andorra",
    ae: "Vereinigte Arabische Emirate",
    af: "Afghanistan",
    ag: "Antigua und Barbuda",
    ai: "Anguilla",
    al: "Albanien",
    am: "Armenien",
    an: "Niederländische Antillen",
    ao: "Angola",
    aq: "Antarktis",
    ar: "Argentinien",
    as: "Amerikanisch-Samoa",
    at: "Österreich",
    au: "Australien",
    aw: "Aruba",
    ax: "Åland",
    az: "Aserbaidschan",
    ba: "Bosnien und Herzegowina",
    bb: "Barbados",
    bd: "Bangladesch",
    be: "Belgien",
    bf: "Burkina Faso",
    bg: "Bulgarien",
    bh: "Bahrain",
    bi: "Burundi",
    bj: "Benin",
    bm: "Bermuda",
    bn: "Brunei Darussalam",
    bo: "Bolivien",
    br: "Brasilien",
    bs: "Bahamas",
    bt: "Bhutan",
    bv: "Bouvetinsel",
    bw: "Botswana",
    by: "Belarus (Weißrussland)",
    bz: "Belize",
    ca: "Kanada",
    cc: "Kokosinseln (Keelinginseln)",
    cd: "Kongo",
    cf: "Zentralafrikanische Republik",
    cg: "Republik Kongo",
    ch: "Schweiz",
    ci: "Elfenbeinküste",
    ck: "Cookinseln",
    cl: "Chile",
    cm: "Kamerun",
    cn: "China, Volksrepublik",
    co: "Kolumbien",
    cr: "Costa Rica",
    cu: "Kuba",
    cv: "Kap Verde",
    cx: "Weihnachtsinsel",
    cy: "Zypern",
    cz: "Tschechische Republik",
    de: "Deutschland",
    dj: "Dschibuti",
    dk: "Dänemark",
    dm: "Dominica",
    do: "Dominikanische Republik",
    dz: "Algerien",
    ec: "Ecuador",
    ee: "Estland (Reval)",
    eg: "Ägypten",
    eh: "Westsahara",
    er: "Eritrea",
    es: "Spanien",
    et: "Äthiopien",
    fi: "Finnland",
    fj: "Fidschi",
    fk: "Falklandinseln (Malwinen)",
    fm: "Mikronesien",
    fo: "Färöer",
    fr: "Frankreich",
    ga: "Gabun",
    gb: "Großbritannien und Nordirland",
    gd: "Grenada",
    ge: "Georgien",
    gf: "Französisch-Guayana",
    gg: "Guernsey (Kanalinsel)",
    gh: "Ghana",
    gi: "Gibraltar",
    gl: "Grönland",
    gm: "Gambia",
    gn: "Guinea",
    gp: "Guadeloupe",
    gq: "Äquatorialguinea",
    gr: "Griechenland",
    gs: "Südgeorgien und die Südl. Sandwichinseln",
    gt: "Guatemala",
    gu: "Guam",
    gw: "Guinea-Bissau",
    gy: "Guyana",
    hk: "Hongkong",
    hm: "Heard- und McDonald-Inseln",
    hn: "Honduras",
    hr: "Kroatien",
    ht: "Haiti",
    hu: "Ungarn",
    id: "Indonesien",
    ie: "Irland",
    il: "Israel",
    im: "Insel Man",
    in: "Indien",
    xk: "Kosovo",
    io: "Britisches Territorium im Indischen Ozean",
    iq: "Irak",
    ir: "Iran",
    is: "Island",
    it: "Italien",
    je: "Jersey (Kanalinsel)",
    jm: "Jamaika",
    jo: "Jordanien",
    jp: "Japan",
    ke: "Kenia",
    kg: "Kirgisistan",
    kh: "Kambodscha",
    ki: "Kiribati",
    km: "Komoren",
    kn: "St. Kitts und Nevis",
    kp: "Nordkorea",
    kr: "Südkorea",
    kw: "Kuwait",
    ky: "Kaimaninseln",
    kz: "Kasachstan",
    la: "Laos",
    lb: "Libanon",
    lc: "St. Lucia",
    li: "Liechtenstein",
    lk: "Sri Lanka",
    lr: "Liberia",
    ls: "Lesotho",
    lt: "Litauen",
    lu: "Luxemburg",
    lv: "Lettland",
    ly: "Libyen",
    ma: "Marokko",
    mc: "Monaco",
    md: "Moldawien",
    mg: "Madagaskar",
    mh: "Marshallinseln",
    mk: "Mazedonien",
    ml: "Mali",
    mm: "Myanmar (Burma)",
    mn: "Mongolei",
    mo: "Macau",
    mp: "Nördliche Marianen",
    mq: "Martinique",
    mr: "Mauretanien",
    ms: "Montserrat",
    mt: "Malta",
    mu: "Mauritius",
    mv: "Malediven",
    mw: "Malawi",
    mx: "Mexiko",
    my: "Malaysia",
    mz: "Mosambik",
    na: "Namibia",
    nc: "Neukaledonien",
    ne: "Niger",
    nf: "Norfolkinsel",
    ng: "Nigeria",
    ni: "Nicaragua",
    nl: "Niederlande",
    no: "Norwegen",
    np: "Nepal",
    nr: "Nauru",
    nu: "Niue",
    nz: "Neuseeland",
    om: "Oman",
    pa: "Panama",
    pe: "Peru",
    pf: "Französisch-Polynesien",
    pg: "Papua-Neuguinea",
    ph: "Philippinen",
    pk: "Pakistan",
    pl: "Polen",
    pm: "St. Pierre und Miquelon",
    pn: "Pitcairninseln",
    pr: "Puerto Rico",
    ps: "Palästina",
    pt: "Portugal",
    pw: "Palau",
    py: "Paraguay",
    qa: "Katar",
    re: "Réunion",
    ro: "Rumänien",
    rw: "Ruanda",
    sa: "Saudi-Arabien",
    sb: "Salomonen",
    sc: "Seychellen",
    sd: "Sudan",
    se: "Schweden",
    sg: "Singapur",
    sh: "St. Helena",
    si: "Slowenien",
    sj: "Svalbard und Jan Mayen",
    sk: "Slowakei",
    sl: "Sierra Leone",
    sm: "San Marino",
    sn: "Senegal",
    so: "Somalia",
    sr: "Suriname",
    st: "São Tomé und Príncipe",
    sv: "El Salvador",
    sy: "Syrien",
    sz: "Swasiland",
    tc: "Turks- und Caicosinseln",
    td: "Tschad",
    tf: "Französische Süd- und Antarktisgebiete",
    tg: "Togo",
    th: "Thailand",
    tj: "Tadschikistan",
    tk: "Tokelau",
    tl: "Timor-Leste",
    tm: "Turkmenistan",
    tn: "Tunesien",
    to: "Tonga",
    tr: "Türkei",
    tt: "Trinidad und Tobago",
    tv: "Tuvalu",
    tw: "Taiwan",
    tz: "Tansania",
    ua: "Ukraine",
    ug: "Uganda",
    um: "Amerikanisch-Ozeanien",
    us: "Vereinigte Staaten von Amerika",
    uy: "Uruguay",
    uz: "Usbekistan",
    va: "Vatikanstadt",
    vc: "St. Vincent und die Grenadinen",
    ve: "Venezuela",
    vg: "Britische Jungferninseln",
    vi: "Amerikanische Jungferninseln",
    vn: "Vietnam",
    vu: "Vanuatu",
    wf: "Wallis und Futuna",
    ws: "Samoa",
    ye: "Jemen",
    yt: "Mayotte",
    za: "Südafrika",
    zm: "Sambia",
    zw: "Simbabwe",
    rs: "Serbien",
    me: "Montenegro",
    bl: "Saint-Barthélemy",
    bq: "Bonaire, Sint Eustatius und Saba",
    cw: "Curaçao",
    mf: "Saint-Martin (franz. Teil)",
    sx: "Sint Maarten (niederl. Teil)",
    ss: "Südsudan",
  },
  response_field_config: {
    heading: { response: "Rückmeldungsfelder", assignment: "Zuweisungen" },
    response: {
      table: {
        header: {
          id: "ID",
          name: "Name",
          type: "Typ",
          internal_number: "Interne Nummer",
          product_category: "Kategorie",
          unit: "Einheit",
          actions: "Aktionen",
        },
        filter: { status: { active: "Aktiv", inactive: "Inaktiv", label: "Aktiv" }, type: { label: "Typ" } },
        field: "Feld hinzufügen",
        assignments: "Zuweisung hinzufügen",
        add_group: "Gruppe hinzufügen",
        add_address: "Adresse hinzufügen",
        add_user: "Benutzer hinzufügen",
        add_entry: "Neuer Eintrag",
        edit_entry: "Eintrag bearbeiten",
        clone_entry: "Eintrag duplizieren",
        deactivate_entry: "Eintrag deaktivieren",
        activate_entry: "Eintrag aktivieren",
        open_detail: "Detailansicht öffnen",
      },
      internal_number_error: "Die interne Nummer muss zwischen 10 und 99 liegen und eindeutig sein.",
      assignment: {
        table: {
          filter: {
            type: { label: "Typ" },
            status: { label: "Status" },
            category: { label: "Kategorie" },
            order_status: { label: "Bestellstatus" },
          },
        },
      },
    },
    response_assignments: {
      status: {
        text: "Status",
        table: {
          header: {
            field: "Feld",
            required: "Erforderlich",
            customer: "Kunde",
            logistic: "Logistiker",
            recycler: "Recycler",
            actions: "Aktionen",
          },
        },
        skip: "Status überspringen",
      },
      table: { header: { id: "ID", type: "Type", name: "Name", actions: "Aktionen" } },
      selection: { title: "Produkt/Zusatzleistung" },
    },
    add: { field: "Feld hinzufügen" },
  },
  responsefields: {
    text: "Text",
    number: "Ganzzahl",
    floating_point: "Fließkomma",
    date: "Datum",
    file: "Datei",
    text_area: "Textbereich",
    already_exists: "Ein Rückmeldungsfeld mit der internen Nummer existiert bereits.",
    save_success: "Das Rückmeldungsfeld wurde erfolgreich gespeichert!",
    to_be_done: "Rückmeldung noch offen",
  },
  notifications: {
    response_assignment_update_success: { message: "Zuweisung erfolgreich gespeichert!" },
    order_saved_success: { message: "Änderung erfolgreich gespeichert!" },
    order_storno_success: { message: "Auftrag erfolgreich storniert!" },
    multiselect_order_buy_success: { message: "Aufträge erfolgreich bestellt!" },
    multiselect_order_delete_success: { message: "Aufträge erfolgreich gelöscht!" },
    order_edit_error: { message: "Fehler beim bearbeiten!" },
    order_load_error: { message: "Fehler beim Laden des Auftrags!" },
    order_create_error: { message: "Fehler beim Erstellen des Auftrags" },
    order_saved_error: { message: "Fehler beim Speichern des Auftrags!" },
  },
  filter: { group: "Gruppe", location: "Standort", product: "Produkt", states: "Status", date: "Datum" },
  clear_all_filters: "Alle Filter entfernen",
  refresh_orders: "Aufträge aktualisieren",
  select_order: "Bestellung auswählen",
  dialog: {
    order_storno_title: { text: "Auftrag stornieren" },
    order_decline: {
      title: "Auftrag ablehnen",
      message: "Hiermit lehnen Sie den Auftrag ab. Diese Aktion kann nicht rückgängig gemacht werden.",
      accept: "Auftrag ablehnen",
    },
    order_storno_message: {
      text: "Der ausgewählte Auftrag wird damit storniert. Dieser Vorgang kann nicht rückgängig gemacht werden! Wollen Sie mit der Stornierung fortfahren?",
    },

    order_storno_button_action: { text: "Ja, stornieren" },
    multiselect_order_buy_button_action: { text: "Ja, bestellen" },
    multiselect_order_buy_message: {
      text: "Die ausgewählten Aufträge werden damit bestellt. Dieser Vorgang kann nicht rückgängig gemacht werden! Wollen Sie mit dem Bestellen fortfahren?",
    },
    multiselect_order_buy_title: { text: "Aufträge bestellen" },
    multiselect_order_delete_button_action: { text: "Ja, löschen" },
    multiselect_order_delete_message: {
      text: "Die ausgewählten Aufträge werden damit gelöscht. Dieser Vorgang kann nicht rückgängig gemacht werden! Wollen Sie mit dem Löschen fortfahren?",
    },
    multiselect_order_delete_title: { text: "Aufträge löschen" },
  },
  cancel: "Stornieren",
  save: "Speichern",
  contact: {
    title: "Hilfe und Kontakt",
    link: "Hilfe & Kontakt",
    text: {
      body: "Für Rückfragen aller Art kontaktieren Sie uns bitte unter {{mail}} und geben Sie relevante Informationen (z.B. Auftragsnummer, Melde-ID, …) an.",
      greeting: "Sehr geehrte Damen und Herren,",
      mail: "portal@grs-batterien.de",
      parting: "Wir freuen uns Ihnen helfen zu können.",
      team: "Ihr GRS-Team",
    },
  },
  dataPrivacy: {
    title: "Datenschutzerklärung",
    link: "Datenschutz",
    text: {
      firstParagraph_subheading: "Datensicherheit",
      dataSecurity_subheading: "Datensicherheit",
      ipaddress_subheading: "Speicherung der IP-Adresse",
      storagePeriod_subheading: "Speicherdauer",
      affectedPerson_subheading: "Ihre Rechte als betroffene Person",
      article15_subheading: "Auskunftsrecht (Art. 15 DSGVO)",
      article16_subheading: "Recht auf Berichtigung (Art. 16 DSGVO)",
      article17_subheading: "Recht auf Löschung (Art. 17 DSGVO)",
      article18_subheading: "Recht auf Einschränkung der Verarbeitung (Art. 18 DSGVO)",
      article20_subheading: "Recht auf Datenübertragbarkeit (Art. 20 DSGVO)",
      article7_subheading: "Widerrufsrecht (Art. 7 DSGVO)",
      article21_subheading: "Widerspruchsrecht (Art. 21 DSGVO)",
      article77_subheading: "Beschwerderecht bei einer Aufsichtsbehörde (Art. 77 DSGVO)",
      enforcement_subheading: "Geltendmachung Ihrer Rechte",
      firstParagraph:
        "Wir freuen uns über Ihren Besuch auf unserer Webseite. Nachfolgend möchten wir Sie über den Umgang mit Ihren Daten gem. Art. 13 Datenschutzgrundverordnung (DSGVO) informieren.",
      dataSecurity:
        "Um Ihre Daten vor unerwünschten Zugriffen möglichst umfassend zu schützen, treffen wir technische und organisatorische Maßnahmen. Wir setzen auf unseren Webseiten ein Verschlüsselungsverfahren ein. Ihre Angaben werden von Ihrem Rechner zu unserem Server und umgekehrt über das Internet mittels einer TLS-Verschlüsselung übertragen. Sie erkennen dies in der Regel daran, dass in der Statusleiste Ihres Browsers das Schloss-Symbol geschlossen ist und die Adresszeile mit https:// beginnt.",
      ipaddress: "Darüber hinaus speichern wir keine IP-Adressen.",
      storagePeriod:
        " Sofern wir nicht im Einzelnen bereits über die Speicherdauer informiert haben, löschen wir personenbezogene Daten, wenn sie für die vorgenannten Verarbeitungszwecke nicht mehr erforderlich sind und keine gesetzlichen Aufbewahrungspflichten einer Löschung entgegenstehen.",
      affectedPerson:
        "Bei der Verarbeitung Ihrer personenbezogenen Daten gewährt die DSGVO Ihnen als betroffene Person bestimmte Rechte: Ihre Rechte als betroffene Person",
      article15:
        "Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob Sie betreffende personenbezogene Daten verarbeitet werden; ist dies der Fall, so haben Sie ein Recht auf Auskunft über diese personenbezogenen Daten und auf die in Art. 15 DSGVO im Einzelnen aufgeführten Informationen.",
      article16:
        "Sie haben das Recht, unverzüglich die Berichtigung Sie betreffender unrichtiger personenbezogener Daten und ggf. die Vervollständigung unvollständiger Daten zu verlangen.",
      article17:
        "Sie haben das Recht, zu verlangen, dass Sie betreffende personenbezogene Daten unverzüglich gelöscht werden, sofern einer der in Art. 17 DSGVO im Einzelnen aufgeführten Gründe zutrifft.",
      article18:
        "Sie haben das Recht, die Einschränkung der Verarbeitung zu verlangen, wenn eine der in Art. 18 DSGVO aufgeführten Voraussetzungen gegeben ist, z. B. wenn Sie Widerspruch gegen die Verarbeitung eingelegt haben, für die Dauer der Prüfung durch den Verantwortlichen.",
      article20:
        "In bestimmten Fällen, die in Art. 20 DSGVO im Einze  lnen aufgeführt sind, haben Sie das Recht, die Sie betreffenden personenbezogenen Daten in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten bzw. die Übermittlung dieser Daten an einen Dritten zu verlangen.",
      article7:
        "Sofern die Verarbeitung von Daten auf Grundlage Ihrer Einwilligung erfolgt, sind Sie nach Art. 7 Abs. 3 DSGVO berechtigt, die Einwilligung in die Verwendung Ihrer personenbezogenen Daten jederzeit zu widerrufen. Bitte beachten Sie, dass der Widerruf erst für die Zukunft wirkt. Verarbeitungen, die vor dem Widerruf erfolgt sind, sind davon nicht betroffen.",
      article21:
        "Werden Daten auf Grundlage von Art. 6 Abs. 1 S. 1 lit. f DSGVO (Datenverarbeitung zur Wahrung berechtigter Interessen) oder auf Grundlage von Art. 6 Abs. 1 S. 1 lit. e DSGVO (Datenverarbeitung zur Wahrung öffentlichen Interesse oder in Ausübung öffentlicher Gewalt) erhoben, steht Ihnen das Recht zu, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Widerspruch einzulegen. Wir verarbeiten die personenbezogenen Daten dann nicht mehr, es sei denn, es liegen nachweisbar zwingende schutzwürdige Gründe für die Verarbeitung vor, die gegenüber Ihren Interessen, Rechten und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.",
      article77:
        "Sie haben gem. Art. 77 DSGVO das Recht auf Beschwerde bei einer Aufsichtsbehörde, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden Daten gegen datenschutzrechtliche Bestimmungen verstößt. Das Beschwerderecht kann insbesondere bei einer Aufsichtsbehörde in dem Mitgliedstaat Ihres gewöhnlichen Aufenthaltsorts, Ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes geltend gemacht werden.",
      enforcement:
        "Sofern vorstehend nichts anderes beschrieben wurde, wenden Sie sich zur Geltendmachung Ihrer Betroffenenrechte bitte an die im Impressum genannte Stelle.",
    },
  },
  error: {
    address: {
      nameTooLong: "Der Name darf maximal 50 Zeichen lang sein!",
    },
    missing_email: "Bitte eine E-Mail eintragen!",
    missing_password: "Bitte Passwort eintragen!",
    basic: {
      headline: "Ein Fehler ist passiert.",
      text: "Bei Ihrer letzten Anfrage ist ein Fehler aufgetreten, sollte das nochmal passieren, kontaktieren Sie bitte den Support",
    },
    login: {
      wrong_credentials: "E-Mail und Passwort stimmen nicht überein.",
      no_active_contracts:
        "Für ihre Gruppe besteht keine Vereinbarung mit der GRS. Bitte kontaktieren Sie den GRS-Support unter portal@grs-batterien.de.",
      too_many_requests: "Zu viele falsche Anmeldeversuche. Versuchen Sie es in 10 Minuten erneut.",
    },
    translation_not_found: "Keine Übersetzung vorhanden!",
  },
  groups: "Gruppen",
  page_not_found: "Seite nicht gefunden",
  status_unknown: "Unbekannt",
  user: {
    reset: {
      password: "Passwort ändern",
      oldPassword: "Altes Passwort",
      newPassword: { text: "Neues Passwort", reenter: "Neues Passwort wiederholen" },
      oldPasswortIncorrect: "Das alte Passwort ist inkorrekt!",
      SuccessfullyReset: "Passwort erfolgreich geändert!",
      noUser: "Es wurde kein zugehöriger Benutzer gefunden.",
      required: "Im Zuge der Anmeldung müssen Sie Ihr Passwort ändern und ein neues Passwort vergeben.",
      notStrongPassword: "Passwörter stimmen nicht überein!",
    },
  },
  locked: {
    headline: "Passwort vergessen?",
    text: "Kein Problem! Hier können Sie Ihr altes Passwort zurücksetzen und ein neues anlegen.",
    reset: { password: { button: "Weiter" } },
    password: {
      reset: {
        success: {
          feedback:
            "Ihre E-Mail-Adresse wird geprüft. Bitte schauen Sie für weitere Anweisungen in Ihr E-Mail Postfach für die hinterlegte Benachrichtigungsadresse und ggf. auch in Ihren Spam-Ordner. Die Überprüfung kann einige Minuten dauern.",
        },
        support:
          "Haben Sie nach einigen Minuten weiterhin keine E-Mail erhalten oder können Sie sich weiterhin nicht anmelden? Unser Support-Team hilft Ihnen gerne unter {{email}} weiter.",
      },
    },
    email: {
      input_title: "E-Mail (login)",
      tooltip: "Bitte geben Sie hier die E-Mail-Adresse ein, für die Sie das Passwort vergessen haben.",
    },
  },
  button: {
    back: { to: { login: "zurück zur Anmeldung" } },
    switch_to_pom: "zu POM wechseln",
    switch_to_cfm: "zu CFM wechseln",
  },
  password: {
    min: {
      symbol: "Muss mindestens ein Sonderzeichen haben",
      upper: "Muss mindestens einen Großbuchstaben haben",
      lower: "Muss mindestens einen Kleinbuchstaben haben",
      length: "Muss mindestens 10 Zeichen lang sein",
      number: "Muss mindestens eine Zahl haben",
    },
  },
  create: {
    user: {
      type: {
        corporate: "CFM Konzernebene",
        waste_producer: " CFM Abfallerzeuger",
      },
      all: { addresses: "Alle Adressen" },
      notify_on_create: "Zugangsdaten an Benutzer senden",
      roleOrTypeRequired: "POM oder CFM Benutzertyp ist verpflichtend.",
    },
  },
  api: { error: { email_already_in_use: "Nutzer bereits im System vorhanden" } },
  app: {
    error: {
      ROW_REFERENCED: "Eintrag konnte nicht geändert werden, da es bereits Daten dazu gibt!",
      PRODUCT_ORDER_MISSING_WASTER_PRODUCER: "Kein Abfallerzeuger selektiert!",
      PRODUCT_ORDER_MISSING_PRODUCT: "Kein Produkt selektiert!",
      PRODUCT_ORDER_MISSING_BILLING_ADDRESS: "Rechnungsanschrift ist leer.",
    },
  },
  validate: { postal: { code: { message: "Geben Sie bitte eine gültige PLZ an!" } } },
  order_filter_search_placeholder: "Aufträge durchsuchen",
  order_filter_search_tooltip:
    "ID, Bestell-Nr./PO, Produkt, Verpackung, Zusatzleistung, Kundenadresse, Abfallerzeuger, Auftragsdatum, Ansprechpartner",
  purchase_order_number: "Bestell-Nr./PO-Nr.",
  datepicker: { general: { cancel: "Abbrechen" } },
  address: { table: { light: { title: "Standorte", addButton: "Standorte hinzufügen", add: "Standort erstellen" } } },
  file: {
    field: { error: { message: "Bitte laden Sie eine Datei hoch" } },
    upload: { error: "Datei zu groß zum Hochladen (mehr als 3MB)" },
  },
  users: { table: { light: { addButton: "Benutzer hinzufügen" } } },
  pom: {
    announcements: {
      create: {
        text: "Meldung einreichen",
        success: "Einreichung wurde erfolgreich durchgeführt",
        error: {
          invalid_input: "Anzahl (Stk) und Gesamtgewicht (kg) müssen ausgefüllt werden!",
          insufficient_contract: "Die Meldeperiode liegt außerhalb Ihres gültigen Vertrags!",
          reduction_not_in_allowed_period: "Meldungsreduzierung außerhalb des erlaubten Zeitraums!",
          undefined: "Meldung konnte nicht eingereicht werden. Ein unbekannter Fehler ist aufgetreten!",
        },
      },
      create_draft: {
        text: "Entwurf zwischenspeichern",
        success: "Meldung wurde erfolgreich zwischengespeichert.",
        error: "Meldung konnte nicht zwischengespeichert werden",
      },
      date: { change: "Meldeperiode ändern" },
      to: { product: { search: "Zur Artikelsuche" } },
      table: {
        count: "Anzahl (Stk.)",
        weight: "Gesamtgew. (kg)",
        price: "Preis",
        empty: "Bitte fügen Sie Artikel aus der Artikelsuche hinzu!",
      },
      product: {
        search: "Art-Nr., Artikel, Gewichtsklasse, Chemie, Abrechnungseinheit",
        table: {
          article_number: "Art-Nr.",
          article: "Artikel",
          type: "Typ",
          weight_class: "Gewichtsklasse",
          chemistry: "Chemie",
          unit: "AE",
          take_back_system_tooltip: "Rücknahmesystem",
          take_back_system: "RS",
          unit_tooltip: "Abrechnungseinheit",
        },
        fetch: { error: "Produkte konnten nicht geladen werden" },
      },
      invalid_input_combination: "Achtung! Kombination aus Menge und Gesamtgewicht nicht valide!",
      invalid_weight:
        "Die Angaben scheinen nicht plausibel. Bitte überprüfen Sie Ihre Angaben für Anzahl (Stk.) und Gewicht (kg).",
      unknown_product:
        "Artikel nicht mehr vorhanden - bitte laden Sie eine aktuelle Artikelliste runter oder löschen Sie das Produkt",
      patch: { error_conflict: "Es gibt bereits eine neuere Version der Meldung. Bitte laden Sie die Seite neu." },
      announcement_date: "Gemeldet am",
      announcement_id: "Melde-ID",
      change_takebacksystem: "Rücknahmesystem wechseln",
      history_date: "Geändert am",
      history_admin_user: "von {{user}} (durch Admin {{admin}})",
      history_user: "von {{user}}",
      export: {
        text: "Excel-Historie",
        error: "Meldung konnte nicht heruntergeladen werden. Versuchen sie es später erneut.",
      },
      excel_export: {
        title: "Meldungen für Zeitraum exportieren",
        error: "Meldungen konnten nicht exportiert werden.",
        from_date: "Von",
        to_date: "Bis",
        export: "Exportieren",
      },
      dialog: {
        title: {
          takebacksystem: "Rücknahmesystem wählen",
          create: "Ihre Meldung wird mit folgenden Positionen eingereicht:",
          update: "Ihre Meldung wird erneut eingereicht. Folgende Positionen werden geändert:",
        },
        takebacksystem: {
          label: "Rücknahmesystem",
          error: "Rücknahmesysteme konnten nicht geladen werden.",
          ok: "Auswählen",
          active: "(aktiv)",
        },
        placeholder: "Bitte Rücknahmesystem wählen",
        ok: "Ja, einreichen",
        ok_update: "Ja, ändern!",
        old_value: "(alt: {{value}})",
      },
      period: { monthly: "Monatlich", yearly: "Jährlich" },
      template: {
        download: {
          text: "Download Vorlage",
          textEn: "Download Template",
          error: "Vorlage konnte nicht heruntergeladen werden. Versuchen sie es später erneut.",
        },
        upload: {
          drop_here:
            "Ausgefüllte Excel-Vorlage zum Hochladen hier ablegen oder hier klicken um die Vorlage auszuwählen.",
          title: "Meldung per Excel",
          dialog: {
            how_to:
              "Laden Sie die Excel-Vorlage herunter und befüllen Sie diese, um die ausgefüllte Vorlage für den Import zu verwenden.",
            description:
              "Importierte Werte werden in die Übersicht der Meldepositionen übernommen. Prüfen Sie diese, bevor Sie Ihre Meldung abschließen.",
            confirm: "Übernehmen",
            success_items: "Anzahl validierter Artikel: ",
            invalid_items: "Anzahl invalider Werte:",
            unknown_items: "Anzahl unbekannter Artikel:",
            rowNumber: "Zeile {{value}}",
            error: {
              wrong_file_type: "Es werden nur Excel-Dateien im XSLX Format unterstützt",
              wrong_file_content:
                "Es scheint sich um eine falsche Excel Datei zu handeln - bitte laden Sie sich die Vorlage herunter und verwenden diese",
              generic: "Import fehlgeschlagen. Bitte nutzen Sie den aktuellen Meldebogen.",
              invalid_items: "Import fehlgeschlagen. Bitte überprüfen Sie Ihre Eingaben",
            },
          },
        },
      },
    },
    manufacturers: {
      create: {
        error: {
          text: "Hersteller konnte nicht erstellt werden!",
          group_fetch: "Gruppen konnten nicht geladen werden!",
        },
        duplicate: {
          title: "Herstellername nicht eindeutig!",
          message:
            "Ein Hersteller mit diesem Namen existiert bereits! Sind Sie sicher, dass sie diesen Hersteller anlegen möchten?",
          action: "Ja, anlegen",
        },
        title: "Hersteller erstellen",
        invalid: { date: "Datum ist nicht gültig!" },
      },
      update: { error: "Hersteller konnte nicht aktualisiert werden!" },
      detail: { title: "Hersteller Details" },
      edit: { title: "Hersteller bearbeiten" },
    },
    agency: { groups: "Herstellerverzeichnis" },
  },
  mail: {
    subject: "Betreff",
    created_at: "Datum",
    to: "An",
    details: "Details",
    general: "E-Mail-Historie",
    name: "E-Mail",
    error: "Beim laden der Mails ist ein Fehler aufgetreten!",
    search: "Von (Email), Von (Alias), An, Betreff",
    searchPlaceholder: "Email suchen",
  },
  manufacturer: { table: { light: { add_button: "Hersteller hinzufügen" } } },
  batt_g: {
    register: {
      not_managed_by_grs_error_title: "Keine EAR-Verwaltung durch GRS",
      not_managed_by_grs_error_message:
        "Gerne unterbreiten wir Ihnen ein Angebot für die Registrierung gem. BattG bei der Stiftung EAR. Bei Interesse senden Sie uns eine formlose Email mit Nennung Ihrer Vertragsnummer an hersteller_ear@grs-batterien.de",
      not_managed_by_grs_error_action: "Email senden",
      manufacturer_data: "Herstellerstammdaten",
      main_contact_data: "Ansprechpartner",
      representative_data: "Vertretungsberechtigte",
      representative_information:
        "Bitte geben Sie hier einen Vertretungsberechtigten bzw. eine Vertretungsberechtigte für Ihr Unternehmen ein.",
      submit_with_fee_button: "Daten kostenpflichtig übertragen",
      save_success: "BattG wurde erfolgreich gespeichert!",
      submit_success: "BattG wurde erfolgreich übertragen!",
      dialog: {
        submit_title: "Daten kostenpflichtig übertragen",
        submit_message:
          "Nach ihrer Zustimmung werden ihre Daten kostenpflichtig von der GRS an die Stiftung EAR übertragen.",
        submit_action: "Übertragen",
      },
      published_at: "Übertragen am {{date}}",
      prefill: {
        main_contact: "Vorauswahl möglicher Ansprechpartner",
        representative: "Vorauswahl möglicher Vertretungsberechtigte",
      },
    },
    brand: {
      table: { name: "Marke", trademark: "Aktenzeichen", battery_type: "Batterieart", status: "Status" },
      add: "Marke hinzufügen",
      update: "Marke editieren",
      dialog: {
        name: "Marke",
        trademark: "Aktenzeichen",
        battery_type: "Batterieart",
        start_date: "Startdatum",
        end_date: "Enddatum",
        take_back_via_grs: "Rücknahme über die GRS",
        take_back_explanation: "Erklärung zu der zumutbaren und kostenfreien Möglichkeit der Rückgabe:",
        take_back_explanation_contact_data:
          "Erklärung zur Zugriffsmöglichkeit der Rückgabeberechtigten auf das Angebot unter der Angabe der Kontaktdaten, bei denen die Rückgabe angemeldet werden kann:",
      },
      warning: {
        no_registration: "Bitte geben Sie Ihre Stammdaten ein.",
        not_represented_via_grs: "Sie werden nicht durch die GRS vertreten. Bitte wenden Sie sich an den GRS-Vertrieb.",
      },
      save_success: "Marke wurde erfolgreich gespeichert!",
      submit_success: "Marke wurde kostenpflichtig übertragen!",
      status: { draft: "Zwischengespeichert", deactivated: "Deaktiviert", published: "Übertragen" },
    },
  },
  order_view: {
    freshdesk_support: "Support-Anfrage zu [{{orderId}}, {{translatedStatus}}]",
    freshdesk_support_successful: "Nachricht erfolgreich verschickt!",
    freshdesk_support_error: "Beim Verschicken der Nachricht ist ein Fehler aufgetreten",
    freshdesk_support_contact: "Kontakt",
    freshdesk_support_message: "Nachricht",
    freshdesk_support_subject: "Betreff",
    contact: "Kontakt: {{mail}}",
  },
  system_cloning: {
    title: "Produktivdaten klonen",
    message:
      "Sind Sie sicher, dass Sie ALLE Daten auf dem aktuellen System löschen und mit den derzeitigen Produktivdaten ersetzen wollen? Der Klonprozess wird im Hintergrund laufen und kann einige Minuten dauern. Bitte melden Sie sich in 2-5 Minuten erneut an und prüfen Sie das Klonergebnis auf dieser Seite. Denken Sie daran, dass dadurch alle Passwörter zurückgesetzt werden!",
    start_cloning: "PROD auf GRS STA spiegeln",
    accept: "Klonprozess starten",
    success: "Spiegelung erfolgreich!",
    error: "Spiegelung nicht erfolgreich!",
    start: {
      success: "Klonprozess erfolgreich gestartet! Sie werden in wenigen Sekunden abgemeldet!",
      error: "Klonprozess konnte nicht gestartet werden! Bitte versuchen Sie es erneut.",
    },
    started_by: "Gestartet von",
    started_at: "Gestartet am",
    finished_at: "Fertiggestellt am",
    status: "Status",
  },
  reCaptcha: {
    captcha_failed: "Die Validierung des Captchas ist fehlgeschlagen!",
  },
  freshdesk: {
    createTicketDialog: {
      subtitle: "",
      description: "Beschreibung",
      subject: "Betreff",
      newErpNumber: "Neue ERP-Nummer",
      newContractNumber: "Neue Vertrags-Nummer",
      reason: "Begründung der Änderung",
      titleErpNumber: "Änderung ERP-Nummer",
      titleContractNumber: "Änderung Vertrags-Nummer",
      currentErpNumber: "Bisherige ERP-Nummer",
      currentContractNumber: "Bisherige Vertrags-Nummer",
      groupName: "Gruppenname",
      issuer: "Antragssteller",
      deepLink: "Link zu den Stammdaten",
      success: "Ticket wurde erfolgreich erstellt.",
    },
  },
};
